<template>
  <div class="mt-14 pb-md-14">
    <v-row justify="center" class="mx-3 mx-md-0">
      <v-col cols="12" md="9" class="d-flex justify-center flex-column">
        <img
          src="../../../../../assets/Manager/personalization/success-audio-emoji.svg"
          alt=""
          class="mt-7 mx-auto"
          :width="smAndDown ? 64 : 80"
        />
        <p class="text-center generating-text mt-5">Your audio is ready!</p>
        <p class="text-center generating-subtext">
          Now that your audio is ready, publish to network to starting using.
        </p>
      </v-col>
      <v-col cols="12" md="7" class="px-1">
        <v-card elevation="0" width="" class="audio-script-card mt-5">
          <div
            class="d-flex justify-space-between align-center py-3 px-4 audio-script-card-language"
          >
            <div
              class="d-flex align-center script-language ga-2 text-capitalize"
            >
              <span>{{
                callConfigStore?.getCurrentSetGreetingAccentInfo?.labels.accent
              }}</span
              >•
              <span
                >{{
                  callConfigStore.getCurrentSetGreetingAccentInfo?.name
                }} </span
              ><span class="audio-accent-gender">
                ({{
                  callConfigStore?.getCurrentSetGreetingAccentInfo?.labels
                    .gender
                }})</span
              >
            </div>
            <div>
              <img
                src="../../../../../assets/Images/edit.svg"
                alt=""
                width="17"
                class="mr-4 pointer"
                @click="openSetupFormForEdit"
              />
              <a
                download="Download Audio"
                :href="callConfigStore?.getCurrentWelcomeAudio?.recording_url"
              >
                <img
                  src="../../../../../assets/Images/icons/download.svg"
                  alt="download"
                  width="17"
                />
              </a>
            </div>
          </div>
          <audio-animation
            class="py-3 px-4"
            :audioFile="callConfigStore?.getCurrentWelcomeAudio?.recording_url"
          ></audio-animation>
        </v-card>

        <div class="mt-10 mt-md-0 mb-n10" v-if="smAndDown">
          <v-divider class="mb-3 mt-16 mt-md-0"></v-divider>

          <v-btn
            flat
            height="40"
            class="filled-primary-color py-3 px-4"
            :block="smAndDown"
            @click="completeSetup"
            :loading="callConfigStore.isPublishingAudio"
          >
            Publish to network
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { onMounted } from "vue";
import {
  getProfessionalGreetingsList,
  useCallConfig,
} from "../../../../../helpers";
import AudioAnimation from "../../AudioAnimation.vue";
import { useCallConfigStore } from "../../../../../stores/call-config.store";
import { useDisplay } from "vuetify";

export default defineComponent({
  components: { AudioAnimation },
  emits: ["nextStep", "completeSetup", "editFlow"],
  setup() {
    const callConfigStore = useCallConfigStore();
    const { smAndDown } = useDisplay();
    const { completeSetup } = useCallConfig();

    const proceedToManageTeam = ref<boolean>(false);

    const openSetupFormForEdit = () => {
      callConfigStore.hasGeneratedWelcomeAudio = false;
      callConfigStore.isGeneratingWelcomeAudio = false;
    };
    const getWelcomeGreetings = async () => {
      // Call again to ensure it gets updated
      try {
        const greetings = await getProfessionalGreetingsList(
          "WELCOME_PROMPT",
          callConfigStore.currentIncomingSetGreeting?.uuid
        );
        callConfigStore.getCurrentWelcomeAudio = greetings.data[0];
      } catch (error) {}
    };

    onMounted(async () => {
      await getWelcomeGreetings();
    });

    return {
      callConfigStore,
      openSetupFormForEdit,
      proceedToManageTeam,
      smAndDown,
      completeSetup,
    };
  },
});
</script>
  
<style scoped>
.audio-duration {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1d2739;
}

.audio-accent-gender {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
}
</style>
  