import { TeamMember, InviteTeamMemberPayload } from "./../helpers/types/teams";
import { defineStore } from "pinia";
import { useNumberStore } from "./numbers.store";
import { useUsersStore } from "./users.store";
import { defaultNewInvitePayload } from "../helpers";
import { AMPLITUDE_EVENTS, trackAmplitudeEvent } from "../helpers";

interface TeamState {
  teamMembers: TeamMember[];
  teamMembersList: TeamMember[];
  showInvitationModal: boolean;
  selectedTeamMember?: any;
  selectedTeamMemberReceiverId?: number | null;
  refetchCallQueue?: boolean;
  hasSuccessfullyInvitedMember?: boolean;
  getRecieversUnderABusiness: any; // used for transfer
  selectedContactQueueTeamMembers?: [];
  newTeamMemberDetails: InviteTeamMemberPayload;
  pendingInvite: boolean;
}

export const useTeamStore = defineStore({
  id: "teammember",
  state: (): TeamState => ({
    teamMembers: [],
    teamMembersList: [],
    showInvitationModal: false,
    selectedTeamMember: null, // this is the user id of the selected team member
    selectedTeamMemberReceiverId: null, // this is the receiver id of the selected team member
    refetchCallQueue: false,
    hasSuccessfullyInvitedMember: false,
    getRecieversUnderABusiness: [],
    selectedContactQueueTeamMembers: [],
    newTeamMemberDetails: defaultNewInvitePayload(),
    pendingInvite: false,
  }),

    getters: {
        getAllTeamMembers: (state: TeamState) => {
            const { activeNumber } = useNumberStore();
            return [...state.teamMembers, activeNumber];
        },
        activeTeamMembers: (state: TeamState) => {
            // move this to store to be reused anywhere
            const userStore = useUsersStore();
            let results = state.teamMembers
                .filter(
                    (member) =>
                        member.user !==
                        userStore.currentUserBusinessLevel?.id &&
                        member.is_active
                )
                .slice(0, 2); // Default to the first 3 active members

            // If no active members are found, return an empty array
            if (results.length < 1) {
                return [];
            }

            return results;
        },
    },
    actions: {
        showTeamModal() {
            this.showInvitationModal = true
            trackAmplitudeEvent(
                AMPLITUDE_EVENTS.ADD_TEAM_MEMBER_CLICKED,
                { is_onboard_page: false }
            )
        }
    },
    persist: {
        storage: localStorage,
        paths: ["hasSuccessfullyInvitedMember", "newTeamMemberDetails", "pendingInvite"],
      },
  });
