export enum INBOUND_CALL_EVENTS {
    INBOUND_CALL_RECEIVED = 'inbound_call_received',
    CALL_PICKUP_ATTEMPTED = 'call_pickup_attempted',
    CALL_PICKUP_SUCCESSFUL = 'call_pickup_successful',
    CALL_PICKED_FAILED = 'call_pickup_failed',
    INBOUND_CALL_CONNECTED_10s_PLUS = 'inbound_call_connected_10s_plus'
}

export enum MANAGER_REPORT_EVENTS {
    AUDIT_REPORT_PAGE_VIEWED = 'audit_report_page_viewed',
    LEADERBOARD_REPORT_PAGE_VIEWED =
    "leaderboard_report_page_viewed",
    TEAM_REPORT_PAGE_VIEWED = "team_report_page_viewed",
    SUMMARY_REPORT_PAGE_VIEWED = "summary_report_page_viewed"
}