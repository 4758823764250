import { BASE_API } from "../constants";
import { useBusinessStore } from "../../stores";
import { getActiveBusinessId } from "..";
import requestClient from "../api/requestClient";

const userId = () => {
  // current business on business level
  const businessStore = useBusinessStore();
  return businessStore.currentUserId;
};

interface ResponseData {
  [key: string]: any;
}

export const fetchReceiversByBusiness = async (): Promise<ResponseData> => {
  // get number under a business
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId()}/`);
};
export const fetchBusinessNumbersUnderABusiness = async (
  businessId?: number
): Promise<ResponseData> => {
  // get number under a business
  return await requestClient.get(
    `${BASE_API}/api/business/${businessId ? businessId : getActiveBusinessId()
    }/number/`
  );
};
