import {
  GreetingToSave,
  fetchGreetingsAccent,
  fetchOnboardingStage,
  fetchProfessionalGreetings,
  saveBasicGreeting,
} from ".";
interface OnboardStatus {
  image: string;
  route: string;
  buttonTitle: string;
}
export const greetingTemplate = [
  "Thank you for reaching out! We are currently out of the office and will be unavailable until [return date]. During this time, we may have limited access to email. If your matter is urgent, please leave a voicemail or send an email to [alternative contact person] at [alternative contact email/phone].",
  "We appreciate your message. Currently, we are away from the office and will not be available until [return date]. For urgent matters, kindly leave a voice mail with your contact details, and we will respond promptly upon our return.",
  "Thank you for reaching out. We are currently out of the office until [return date]. If your matter requires immediate attention, please leave a detailed voice message, and we will get back to you as soon as possible.",
];
export const incomingGreetingTemplate = [
  "Hello and welcome! You've reached [Company Name]. We're here to assist you on your health journey. Please stay on the line for personalized care and support.",
  "Hello and welcome! You've reached [Your Company Name]. We're here to assist you on your health journey. Please stay on the line for personalized care and support.",
  "Hello and welcome! You've reached [Your Company Name]. We're here to assist you on your health journey. Please stay on the line for personalized care and support.",
];
export const onboardStatusCustomization: OnboardStatus[] = [
  {
    image: "../src/assets/Manager/setup-status/verify.svg",
    route: "/out-of-office",
    buttonTitle: "Verify",
  },
  {
    image: "../src/assets/Manager/setup-status/out-of-office.svg",
    route: "/out-of-office",
    buttonTitle: "Set up",
  },
  {
    image: "../src/assets/Manager/setup-status/test-call.svg",
    route: "/out-of-office",
    buttonTitle: "Set Up",
  },
  {
    image: "../src/assets/Manager/setup-status/invite-team.svg",
    route: "/out-of-office",
    buttonTitle: "Invite team",
  },
  {
    image: "../src/assets/Manager/setup-status/tour.svg",
    route: "/out-of-office",
    buttonTitle: "Watch Tour",
  },
  {
    image: "../src/assets/Manager/setup-status/call-flow.svg",
    route: "/out-of-office",
    buttonTitle: "Make a Call",
  },

  {
    image: "../src/assets/Manager/setup-status/call-flow.svg",
    route: "/out-of-office",
    buttonTitle: "Set up",
  },
];
export function generateTimeOptions() {
  const timeOptions = [];
  const periods = ["AM", "PM"];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
      const periodIndex = Math.floor(hour / 12);
      const period = periods[periodIndex];
      const time = `${formattedHour}:${minute === 0 ? "00" : minute} ${period}`;
      const value = `${hour < 10 ? "0" : ""}${hour}:${minute === 0 ? "00" : minute
        }`;
      timeOptions.push({ name: time, value: value });
    }
  }
  timeOptions.push({ name: "11:59 PM", value: "23:59" });
  return timeOptions;
}

export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const getListOfOnboardingStage = async (): Promise<any> => {
  try {
    const statusList = await fetchOnboardingStage();
    return statusList.data;
  } catch (error) {
    return null;
  }
};
export const getProfessionalGreetingsList = async (
  type?: string,
  uuid?: string
) => {
  try {
    const greetingList = await fetchProfessionalGreetings(type, uuid);
    return greetingList.data;
  } catch (error) {
    return [];
  }
};
export const getAccents = async () => {
  try {
    const listOfAccents = await fetchGreetingsAccent();
    return listOfAccents.data;
  } catch (error) {
    return [];
  }
};
export const requestForGreeting = async (payload: GreetingToSave) => {
  try {
    const saveGreeting = await saveBasicGreeting(payload);
    return saveGreeting.data;
  } catch (error) {
    return {};
  }
};
// Validation
export const messageRule = [
  (v: string) => !!v || "A greeting script is required to complete this action",
];

// incoming flows

export const allQuestionsGoGlobal = [
  {
    id: "BusinessRoles",
    title: "What best describes you?",
    options: [
      "Freelancer",
      "Business Owner",
      "C-Suite Executive",
      "Marketing Executive",
    ],
    rowType: "box",
  },
  {
    id: "HowManyPeopleWillUseThisNumber",
    title: "How many people will use this number?",
    options: ["Just me", "Multiple people"],
    rowType: "box",
  },
  {
    id: "UsageScenario",
    title: "What best describes what you will be using PressOne for?",
    options: [
      "Business Registration in another country",
      "I want to register on a platform e.g Paypal",
      "I want to run Ads",
      "Make and receive business calls",
    ],
    rowType: "reasonBox",
  },
  {
    id: "ClientStatus",
    title: "What best describes your scenario?",
    options: [
      "I have an existing database of international Clients",
      "I do not have International clients",
    ],
    rowType: "box",
  },
  {
    id: "ServicesSold",
    title: "What product or service do you sell?",
    options: [
      "Business Consulting",
      "Software Development",
      "Marketing Strategy",
      "Legal Services",
      "Others",
    ],
    rowType: "box",
  },
];
