import { defineStore } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    VoiceAccent,
    VoiceScript,
    getAccents,
    getCallFlows,
    getProfessionalGreetingsList,
} from "../helpers";
import { notify } from "@kyvg/vue3-notification";
import { useTeamStore } from "./team-member.store";
import { callOptionType, ConfigureMenuPayload } from "../helpers/types/call-config";
import { useBusinessStore } from "./businesses.store";

interface Schedule {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}
interface CurrentSetCompanyGreeting {
    business_number: number;
    text: string;
    greeting_type: string;
    voice_accent: string;
    greeting_name: string;
    uuid: string;
}

interface CallConfigState {
    currentSetBusinessHours: Schedule | any;
    welcomeProfessionalGreetingList: VoiceScript[] | [];
    outofOfficeProfessionalGreetingList: VoiceScript[] | [];
    currentIncomingSetGreeting: CurrentSetCompanyGreeting | null;
    currentOutOfSetGreeting: CurrentSetCompanyGreeting | null;
    accents: VoiceAccent[] | null;
    currentStep: number;
    selectedTemplateIndex: number | null;
    isOutOfoffice: boolean;
    selectedVoiceAccent: string | null;
    selectedAccent: string | null;
    greetingActivatingProgress: number;
    isPublishingAudio: boolean;
    isPublished: boolean;
    teamMembers?: any;
    entries:
    | any
    | Record<
        string,
        { id: string; digit: string; kind: string; param: number }
    >;
    allCallFlow: any;
    isGettingFlow: boolean;
    flowToEdit: any;
    editFlow: boolean;
    requestPayload: {
        name: string;
        selectedIncomingTemplate: string;
        selectedOutOfOfficeTemplate: string;
        call_forwarding_line: string | null | undefined;
        selectedForward: string;
        selectedRingRule?: string;
    };
    getCurrentWelcomeAudio: null | any;
    getCurrentOutOfOfficeAudio: null | any;
    isGeneratingWelcomeAudio: boolean;
    hasGeneratedWelcomeAudio: boolean;
    isGeneratingOutOfOfficeAudio: boolean;
    hasGeneratedOutOfOfficeAudio: boolean;
    isCompletingSetupFlow: boolean;
    assignNumberForFirstFlow: boolean;
    teamMemberSelection: any;
    applyIvr: boolean;
    listOfTeamsMemberToUpdate: any;
    flowToAssignAfterCreate: string | null;
    showTemplateNav: boolean;
    newGreeting: boolean;
    newOOFGreeting: boolean;
    // new implementation
    teamMembersManage: any
    tempSelectedMembers: any
    callOptionType: callOptionType;
    teamMembersMenu: any;
    showMenuOptions: boolean;
    configuredMenu: ConfigureMenuPayload[] | [];
    configureMenuPayload: ConfigureMenuPayload | null;
    menuOptionToEdit: any;
    menuOptionToEditIndex: number;
    businessNumbersToAssignFlow: { business_numbers: number[] };
    useAudioPromptForWelcomeGreeting: boolean;
    useAudioPromptForOOFGreeting: boolean;
    welcomeGreetingUploadedInfo: {} | null;
    oofGreetingUploadedInfo: {} | null;
    isUploadingAudioScript: boolean;
    isRetrievalError: boolean;
    isUploadIsError: boolean;
}

export const useCallConfigStore = defineStore({
    id: "call-config",
    state: (): CallConfigState => ({
        currentSetBusinessHours: {},
        welcomeProfessionalGreetingList: [],
        outofOfficeProfessionalGreetingList: [],
        currentIncomingSetGreeting: null, // used later to filter greeting
        currentOutOfSetGreeting: null, // used later to filter greeting
        accents: null,
        currentStep: 0,
        selectedTemplateIndex: null,
        selectedAccent: null, // country accent
        selectedVoiceAccent: null,
        isOutOfoffice: false, // use to toggle the greeting sample template rendered
        greetingActivatingProgress: 0,
        isPublishingAudio: false,
        isPublished: false,
        entries: {},
        teamMembers: [],
        allCallFlow: [], // all the call config after fetch
        isGettingFlow: false,
        flowToEdit: {},
        editFlow: true,
        requestPayload: {
            name: "",
            selectedIncomingTemplate: "",
            selectedOutOfOfficeTemplate: "",
            call_forwarding_line: useTeamStore().teamMembers?.find(
                (item) => (item?.role === "owner")
            )?.mobile, // get owner number to set call forwarding line
            selectedForward: "callForwarding",
            selectedRingRule: "simultaneous",
        },
        getCurrentWelcomeAudio: null,
        getCurrentOutOfOfficeAudio: null,
        isGeneratingWelcomeAudio: false,
        hasGeneratedWelcomeAudio: false,
        isGeneratingOutOfOfficeAudio: false,
        hasGeneratedOutOfOfficeAudio: false,
        isCompletingSetupFlow: false,
        assignNumberForFirstFlow: false,
        teamMemberSelection: [
            ...useTeamStore().teamMembers?.sort((a, b) => a?.user - b?.user),
        ],
        applyIvr: false,
        listOfTeamsMemberToUpdate: [],
        flowToAssignAfterCreate: null,
        showTemplateNav: false,
        newGreeting: false,
        newOOFGreeting: false,
        // 
        teamMembersManage: [],
        tempSelectedMembers: [], // temporarily list team memeber and update the permanent one on apply
        callOptionType: {
            name: "Ring for All",
            value: "ring-all",
            tooltip: "All team members will receive the incoming call simultaneously",
            destination_type: "call_center",
        },
        // menuoption
        teamMembersMenu: [],
        showMenuOptions: false,
        // new menuoption
        configuredMenu: [],
        configureMenuPayload: null,
        menuOptionToEdit: null,
        menuOptionToEditIndex: 0,
        businessNumbersToAssignFlow: { business_numbers: [] },
        useAudioPromptForWelcomeGreeting: true,
        useAudioPromptForOOFGreeting: true,
        welcomeGreetingUploadedInfo: null,
        oofGreetingUploadedInfo: null,
        isUploadingAudioScript: false,
        isRetrievalError: false,
        isUploadIsError: false

    }),
    getters: {
        getCurrentSetGreetingAccentInfo(state) {
            if (
                state.welcomeProfessionalGreetingList &&
                state.currentIncomingSetGreeting
            ) {
                return state.accents?.find(
                    (greeting) =>
                        greeting.voice_id ===
                        state.currentIncomingSetGreeting?.voice_accent
                );
            }
        },
        getCurrentSetOutOfOfficeAccentInfo(state) {
            if (
                state.outofOfficeProfessionalGreetingList &&
                state.currentOutOfSetGreeting
            ) {
                return state.accents?.find(
                    (greeting) =>
                        greeting.voice_id ===
                        state.currentOutOfSetGreeting?.voice_accent
                );
            }
        },
        teamMembersOrder(): any {
            // arrange the user id for ring group based on order
            if (this.teamMemberSelection) {
                return this.teamMembersManage.map((item: any) => item?.user);
            } else return [];
        },
        callDestinationType(): string {
            return this.callOptionType?.destination_type!;
        },
        isEveryDateMatching(): boolean {
            // checks if everyday matches format beolow. this means business hours was never set
            const expectedSchedule = {
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
            } as any;

            for (const day in expectedSchedule) {
                if (
                    (Object.keys(this.currentSetBusinessHours).length > 0 &&
                        !this.currentSetBusinessHours.hasOwnProperty(day)) ||
                    this.currentSetBusinessHours[day] !== expectedSchedule[day]
                ) {
                    return false;
                }
            }

            return true;
        },
        isAllowedToAccessCallCenter(): boolean {
            const { currentBusinessId } = useBusinessStore()
            const allowedBusinessUsersForCallCenter = [2163, 3603, 3396, 285, 2747, 3211, 2761, 3775]
            return allowedBusinessUsersForCallCenter.includes(Number(currentBusinessId));
        }
    },
    actions: {
        async getGreetingsByType(prompt: string) {
            try {
                const greetings = await getProfessionalGreetingsList(prompt);
                if (prompt === "WELCOME_PROMPT")
                    this.welcomeProfessionalGreetingList = greetings?.data;
                if (prompt === "OUT_OF_OFFICE_PROMPT")
                    this.outofOfficeProfessionalGreetingList = greetings?.data;
            } catch (error) { }
        },
        async getAccentLists() {
            try {
                const accents = await getAccents();
                this.accents = accents;
            } catch (error) { }
        },
        populateInput(item: string, index: number) {
            this.selectedTemplateIndex = index;
            if (!this.isOutOfoffice)
                this.requestPayload.selectedIncomingTemplate = item;
            else this.requestPayload.selectedOutOfOfficeTemplate = item;
        },
        clearStore() {
            this.entries = {};
            this.currentStep = 0;
            this.isPublished = false;
            this.isPublishingAudio = false;
            this.currentSetBusinessHours = {};
            this.isCompletingSetupFlow = false;
            this.hasGeneratedOutOfOfficeAudio = false;
            this.isGeneratingOutOfOfficeAudio = false;
            this.hasGeneratedWelcomeAudio = false;
            this.isGeneratingWelcomeAudio = false;
            this.requestPayload = {
                name: "",
                selectedIncomingTemplate: "",
                selectedOutOfOfficeTemplate: "",
                call_forwarding_line: useTeamStore().teamMembers?.find(
                    (item) => (item?.role === "owner" || item?.role === "manager")
                )?.mobile,
                selectedForward: "callForwarding",
                selectedRingRule: "simultaneous",
            };
            this.editFlow = false;
            this.currentIncomingSetGreeting = null;
            this.getCurrentOutOfOfficeAudio = null;
            this.getCurrentWelcomeAudio = null;
            this.newGreeting = false;
            this.newOOFGreeting = false;
            this.teamMembers = [];
            this.teamMemberSelection = [...useTeamStore().teamMembers].sort(
                (a: any, b: any) => a?.user - b?.user
            );
            this.applyIvr = false;
            this.configuredMenu = [];
            this.configureMenuPayload = null;
            this.menuOptionToEdit = null;
            this.menuOptionToEditIndex = 0;
            this.callOptionType = {
                name: "Ring for All",
                value: "ring-all",
                tooltip: "All team members will receive the incoming call simultaneously",
                destination_type: "call_center",
            };
            this.showMenuOptions = false;
            this.businessNumbersToAssignFlow = { business_numbers: [] }
            this.welcomeGreetingUploadedInfo = null
            this.oofGreetingUploadedInfo = null

            // sort to prevent js from reaarange the route number
        },
        async getIncomingFlow(link?: string) {
            try {
                this.allCallFlow = []; // clears store if cached there
                this.isGettingFlow = true;
                const response = await getCallFlows(link);
                this.allCallFlow = response?.data;
                this.isGettingFlow = false;
            } catch (error: any) {
                this.isGettingFlow = false;
                notify({
                    text: `${error?.message || error}`,
                    type: 'error'
                });
            }
        },
    },

    persist: PINIA_PERSIST_OPTIONS,
});
