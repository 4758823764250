<template>
  <div class="my-16">
    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex justify-center flex-column">
        <img
          src="../../../../../assets/Manager/personalization/is-genrating-audio.svg"
          alt=""
          class="mt-16 mx-auto"
          width="80"
        />
        <p class="text-center generating-text mt-5">
          We are generating your audio
        </p>
        <p class="text-center generating-subtext">
          Hang in there, this might take a while
        </p>
        <div class="meter-progress mt-8 mx-4 mx-md-0" style="height: 20px">
          <span
            :style="{
              width: callConfigStore.greetingActivatingProgress + '%',
            }"
          ></span>
        </div>
      </v-col>
      <!-- <v-col cols="5" class="d-flex justify-center">
        <button
          class="outline-primary-color py-2 px-4 font-weight-bold"
          width="50px"
        >
          Cancel
        </button>
      </v-col> -->
    </v-row>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useCallConfigStore } from "../../../../../stores";

export default defineComponent({
  setup() {
    const callConfigStore = useCallConfigStore();
    return {
      callConfigStore,
    };
  },
});
</script>

<style scoped>
.meter-progress {
  box-sizing: content-box;
  height: 10px;
  position: relative;
  margin: 20px 0 20px 0;
  background: #f1f2f5;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter-progress > span {
  display: block;
  height: 100%;
  border-radius: 20px;
  background-color: #0dcbfa;
  background-image: linear-gradient(to left, white, #0dcbfa, #02bbf1);
  position: relative;
  overflow: hidden;
}
.meter-progress > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease; /* Use 'ease' timing function */
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.generating-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #344054;
}
.generating-subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #667185;
}
</style>
