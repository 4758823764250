import { defineStore, getActivePinia } from "pinia";
import {
  PINIA_PERSIST_OPTIONS,
  requestLoginOTP,
  ISignIn,
  handleFetchUserDetails,
  trackAmplitudeEvent,
  encryptKey,
  verifyEncryptedLoginToken,
  getUserDetailsByTokenNewImplementation,
  logoutAndClearCookies,
  updateFeedbackSheet,
  loginWithMagicLink,
  getIP,
} from "../helpers";
import {
  useUsersStore,
  useUtilsStore,
  useNumberStore,
  useBusinessStore,
  useDialerStore,
  usePersonalizeStore,
  BusinessList,
} from ".";
import router from "../router";
import { setTrackedUser, setUserProperties } from "../helpers";
import { AMPLITUDE_EVENTS } from "../helpers/integrations/analytics/events";
import * as Sentry from "@sentry/vue";
import { encryptToken } from "../helpers/encryptionKeys";
import logger from "../helpers/logger";
import { notify } from "@kyvg/vue3-notification";

interface AuthState {
  mobile: string | null;
  token: string | null;
  otpSent: boolean;
  returnUrl: string | null;
  email: string | null;
  loading: boolean;
  authError: string;
  selectedPlanId?: number | string;
  couponCode?: string;
  scheme: string; // otp sender type
  encryptionData: any;
  isLoggingOut: boolean;
  userPropertySentToAmplitude:
  | {
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
  }
  | {};
  resetPasswordData: {
    email: string;
    uid: string;
    token: string;
  };
  emailSignInErrMsg: string;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    // initialize state from local storage to enable user to stay logged in
    mobile: null,
    otpSent: false,
    token: null,
    email: null,
    returnUrl: null,
    loading: false,
    authError: "",
    scheme: "",
    encryptionData: null,
    couponCode: "",
    isLoggingOut: false,
    resetPasswordData: {
      email: "",
      uid: "",
      token: "",
    },
    userPropertySentToAmplitude: {},
    emailSignInErrMsg: "",
  }),
  getters: {
    isAuthenticated: (state: AuthState) => !!state.token,
  },
  actions: {
    async getOTP(payload: ISignIn) {
      this.authError = "";
      this.loading = true;
      this.mobile = payload.mobile;
      const responseKeyData = await encryptKey({ login_id: this.mobile }); // get keys and session id
      this.encryptionData = responseKeyData.data;
      const response = await requestLoginOTP(payload);

      this.email = response.data.email;
      return response; // we intentionally did not catch error
    },

    async login(
      payload: any,
      isEmailLogin = false,
      emailAddress?: string,
      isMagicLinkLogin = false
    ) {
      const personalization = usePersonalizeStore();

      this.loading = true;
      this.authError = "";
      let reqData;

      try {
        if (!isEmailLogin && !isMagicLinkLogin) {
          const encryptedToken = encryptToken(
            this.encryptionData.public_key,
            payload.token
          ); // encrypt token
          const requestPayload = {
            // new encryption payload
            login_id: payload.mobile,
            auth_session_id: this.encryptionData.auth_session_id,
            otp: encryptedToken, // replaces the former token
          };
          const { data } = await verifyEncryptedLoginToken(requestPayload);
          reqData = data;
          // console.log(data);
          // this.token = data.token;
        } else if (isEmailLogin) {
          reqData = payload;
        } else {
          const token = payload.token;
          const { data } = await loginWithMagicLink(token);
          this.emailSignInErrMsg = "";

          reqData = data;
        }
        this.token = reqData.token;

        const currentUser = await getUserDetailsByTokenNewImplementation().then(
          (res: any) => res.data
        );
        this.mobile = currentUser.mobile_phone;

        const businessStore = useBusinessStore();
        const userStore = useUsersStore();
        const utilsStore = useUtilsStore();
        const numberStore = useNumberStore();

        userStore.currentUserBusinessLevel = currentUser;
        localStorage.setItem("token", reqData.token);

        businessStore.businesses = userStore.userProfile;
        if (businessStore.businesses?.length && !businessStore.activeBusiness) {
          // sets the business associated with the email used to login in case its email Login
          if (emailAddress) {
            const emailAddressBusiness = businessStore.businesses?.find(
              (business: BusinessList) => {
                return business?.email === emailAddress;
              }
            ) as BusinessList;

            businessStore.activeBusiness = emailAddressBusiness;
          } else {
            // sets the first element in the array to the active number if no active number exists
            businessStore.activeBusiness = businessStore.businesses[0];
          }
        }

        setTrackedUser(userStore?.currentUserBusinessLevel?.personal_email); // tracks user using unique id i.e email
        setUserProperties({
          email: userStore?.currentUserBusinessLevel?.personal_email,
          phone: userStore.currentUserBusinessLevel?.mobile,
          first_name: userStore?.currentUserBusinessLevel?.first_name,
          last_name: userStore?.currentUserBusinessLevel?.last_name,
        });
        trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN); // tracks login event
        if (utilsStore.signInSource) {
          trackAmplitudeEvent(`User Signin - ${utilsStore.signInSource}`); // tracks the source of the login
        }
        Sentry.configureScope(function (scope) {
          scope.setUser({
            email: userStore?.currentUserBusinessLevel?.personal_email,
          });
        });
        try {
          //check if the user has a receiver

          if (userStore.currentUserBusinessLevel?.profiles?.length < 1) {
            // NO PENDING PAYMENT CHECK FROM SIGNIN ANYMORE. USERS THAT DON'T HAVE PROFILE SIMPLY HAVEN'T PAID
            // PROFILES ARE NOW CREATED IMMEDIATELY YOU PAY SINCE BUSINESS NUMBER IS ALSO BEING PROVIDED FROM PAYMENT

            router.push("/complete-signup");
          } else {
            // assign the user_role for the user to the role of the first receiver
            if (businessStore.activeBusiness) userStore.user_role =
              businessStore.activeBusiness.role;
            await getIP(); // get IpAddress
            if (
              userStore.user_role === "owner" ||
              userStore.user_role === "manager"
            ) {
              await handleFetchUserDetails();
              await numberStore.getUserPhonesFromBusiness();

              // call the onboardStatus to get new payment status
              await personalization.fetchOnboardingStatus();
              // sets feedback to true for new users. allow only existing users see modal
              if (
                personalization.userOnboardingStatus?.has_provisioned_number ===
                false
              ) {
                // false is for new user;
                await updateFeedbackSheet();
              }
              if (businessStore.goGlobalFirstSignIn) {
                router.push("/managers/engagement");
                return;
              } else {
                // on mobile is onboard process is incomplete redirect to onboard page
                utilsStore.isMobile && personalization.completedTasksCount < 4 ? router.push("/managers/initial-user-onboarding") : router.push("/managers/activities");
              }
              if (this.returnUrl) //  redirects to prev page before authentication
                router.push(this.returnUrl)
              else utilsStore.isMobile && personalization.completedTasksCount < 4 ? router.push("/managers/initial-user-onboarding") : router.push("/managers/activities");
            } else {
              if (
                !userStore.currentUserBusinessLevel?.last_active &&
                !businessStore.activeProfile?.has_filled_engagement_form
              )
                // for first time agents also
                router.push("/agents/engagement");
              // redirect to engagement page
              else {
                if (this.returnUrl)
                  router.push(this.returnUrl)
                else router.push("/");
              }
            }
          }
        } catch (error) {
          logger.error(error, "Error occured during Authentication LN:163");
          // Theres no point redirecting on error
          // router.push("/");
        }
      } catch (error: any) {
        this.loading = false;
        let errorMsg = "";

        if ((error?.message).includes("expired or is invalid")) {
          this.emailSignInErrMsg = error;
        } else {
          notify({
            type: "error",
            text: error?.message || "Something went wrong, please try again",
          });
        }

        this.loading = false;
        this.authError = errorMsg;
        throw errorMsg;
      }

      this.encryptionData = null;
    },
    clearStores() {
      //clear stores
      const userStore = useUsersStore();
      userStore.$reset();
      const authStore = useAuthStore();
      authStore.$reset();
      const utilsStore = useUtilsStore();
      utilsStore.$reset();
      const numbersStore = useNumberStore();
      numbersStore.$reset();
      const dailerStore = useDialerStore();
      dailerStore.$reset();

      // map through that list and use the **$reset** fn to reset the state
      (getActivePinia() as any)._s.forEach((store: any) => store.$reset());
    },
    async CLEAR_X_REFRESH_TOKEN() {
      try {
        await logoutAndClearCookies();
      } catch (error) {
        logger.error(error, "refresh logout token error");
      }
    },
    async logout() {
      this.isLoggingOut = true;
      this.token = null;
      this.clearStores();
      this.mobile = null;
      Sentry.setUser(null);
      const token = localStorage.getItem("token");
      const isPressoneAccountState = localStorage.getItem("isPressOneAccount");
      if (token) {
        localStorage.removeItem("token");
        localStorage.removeItem("users");
        localStorage.removeItem("auth");
        // localStorage.removeItem("utils");
        localStorage.removeItem("numbers");
        localStorage.removeItem("dialer");
      }
      document.cookie.split(";").forEach(function (cookie) {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      localStorage.clear();
      sessionStorage.clear(); // clear
      localStorage.setItem(
        "isPressOneAccount",
        isPressoneAccountState || "false"
      );

      await router.push("/sign-in");
      this.isLoggingOut = false;
    },
  },
  persist: PINIA_PERSIST_OPTIONS,
});
