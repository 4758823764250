import { BASE_API } from "../constants";
import { getActiveBusinessId } from "../queries";
import { fetchData, postData } from "../conversations";
import requestClient from "../api/requestClient";

interface ResponseData {
  [key: string]: any;
}

export const getConversations = async (
  filter_option?: string,
  search?: string
): Promise<ResponseData> => {
  if (filter_option) {
    return await requestClient.get(
      `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/?page_index=1&page_size=100&${filter_option}`
    );
  }
  if (search) {
    return await requestClient.get(
      `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/?page_index=1&page_size=100&search=${search}`
    );
  }
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/?page_index=1&page_size=100`
  );
};

export const createConversation = async (payload: string) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/`,
    { json: payload }
  );
};

export const getExistingContactConversation = async (
  contact_id: string | number
) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/existing-conversations/${contact_id}/`
  );
};

export const leaveConversation = async (conversation_id: string | null) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/leave/`
  );
};

export const updateConversation = async (
  conversation_id: string | null,
  payload: unknown
) => {
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/`,
    { json: payload });
};

export const createConversationItem = async (
  conversation_id: string | null,
  payload: unknown
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/items/`,
    { json: payload }
  );
};

export const getConversationItems = async (conversation_id: string | null) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/items/?page_index=1&page_size=100`
  );
};

export const createConversationNote = async (
  conversation_id: string | null,
  payload: unknown
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/notes/`,
    { json: payload }
  );
};

export const getConversationNotes = async (conversation_id: string | null) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/notes/?page_index=1&page_size=100`
  );
};

export const inviteParticipants = async (
  conversation_id: string,
  payload: unknown
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/participants/`,
    { json: payload }
  );
};

export const getInvitationDetails = async (
  conversation_id: string,
  invitation_id: string,
  token: string
) => {
  return await fetchData(
    `${BASE_API}/api/business/conversations/${conversation_id}/invitation/${invitation_id}/`,
    token
  );
};

export const getConversationItemsForGuest = async (
  conversation_id: string | null,
  invitation_id: string | null,
  token: string
) => {
  return await fetchData(
    `${BASE_API}/api/business/conversations/${conversation_id}/invitation/${invitation_id}/guest/items/?page_index=1&page_size=100`,
    token
  );
};

export const createConversationItemForGuest = async (
  conversation_id: string | null,
  invitation_id: string | null,

  token: string,
  payload: unknown
) => {
  return await postData(
    `${BASE_API}/api/business/conversations/${conversation_id}/invitation/${invitation_id}/guest/items/`,
    token,
    payload
  );
};

export const getPreAssignedUrlForFile = async (
  conversation_id: string | null,
  payload: unknown
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/conversations/${conversation_id}/presigned-url/`,
    { json: payload }
  );
};
