import { defineStore } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    updateUserDetails,
    recommendationData,
    getUserDetailsByTokenNewImplementation,
    SignupData,
} from "../helpers";

interface ResponseData {
    [key: string]: any;
}
type Feedback = Record<string, {
    title: string;
    description: string;
    img: string;
    event: string;
}>;
export const useUsersStore = defineStore({
    id: "users",
    state: () => ({
        users: {},
        user_role: "",
        currentUser: null as ResponseData | null, // currentUser can be of type ResponseData or null
        currentUserBusinessLevel: null as ResponseData | null, // currentUser can be of type ResponseData or null
        registerUser: {
            id: "",
            first_name: "",
            last_name: "",
            mobile: "",
            personal_email: "",
        }, //user signup data
        registerBusiness: {} as recommendationData,
        recommended_plan: {},
        activeTab: 0, //for complete sign-up view
        permissions: {} as any,
        showFeedBackModal: true,
        NIN: "",
        hasFilledEngagement: false,
        savedSignupDetails: null as null | SignupData,
        userFeedbackModal: false,
        feedback: {
            callerGreeting: {
                title: 'Rate your set up experience',
                description: 'How would you rate the experience of setting up your first caller greeting. Your feedback would help us improve your experience.',
                img: '/assets/Images/feedback/greetings.png',
                event: 'professional_greeting'
            }
        } as Feedback,
        currentFeedback: {} as Feedback[keyof Feedback] & {
            data?: Record<string, string>
        } | null
    }),
    getters: {
        getAgentRole: (state) => {
            return state.permissions || {};
        },
        userProfile: (state) => {
            return state.currentUserBusinessLevel?.profiles;
        },
        businesses: (state) => {
            return state.currentUserBusinessLevel?.business;
        },
    },
    actions: {
        async updateUser(payload: any) {
            await updateUserDetails(payload).then((res: any) => res.data);

            const currentUser =
                await getUserDetailsByTokenNewImplementation().then(
                    (res: any) => res.data
                );

            this.currentUserBusinessLevel = currentUser;
        },

        async fetchUserDetails() {
            const currentUser =
                await getUserDetailsByTokenNewImplementation().then(
                    (res: any) => res.data
                );
            this.currentUserBusinessLevel = currentUser;
        },
        setFeedBack(feature: string) {
            this.currentFeedback = this.feedback[feature]
        }
    },
    persist: PINIA_PERSIST_OPTIONS,
});
