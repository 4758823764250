import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import { getActiveBusinessId } from "../queries";


// Fetch authentication token
export const fetchSurrealAuthToken = async () => {
  return await requestClient.get(`${BASE_API}/api/auth/token/surreal/?business_id=${getActiveBusinessId()}`);
};

