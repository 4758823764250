<template>
  <v-form class="pl-md-3 px-4 pr-md-6" @submit="requestGreeting" ref="form">
    <div class="d-flex ga-2 align-center mb-3">
      <p class="custom-greeting-label">Your Greeting</p>

      <Tooltip>
        <img
          src="../../../../../assets/Images/icons/question.svg"
          alt=""
          class="mt-2"
        />
        <template v-slot:tooltip>
          <span
            >This is what customers here when they call your number, before it
            starts to ring. It is a great way to introduce your business and
            present a professional front.</span
          >
        </template>
      </Tooltip>
    </div>
    <v-textarea
      class="custom-greeting-input mb-4"
      color="#d0d5dd"
      v-model="callConfigStore.requestPayload.selectedIncomingTemplate"
      rounded="lg"
      :rows="!smAndDown ? 4 : 2"
      no-resize
      variant="solo"
      flat
      :rules="messageRule"
      autofocus
    ></v-textarea>
    <div
      class="d-flex align-center ga-2 mb-3 pointer"
      @click="toggleTemplateNav"
      v-if="!smAndDown"
    >
      <img
        src="../../../../../assets/Manager/personalization/greeting-template.svg"
        alt=""
      />
      <p class="text-underline greeting-template">Greeting Templates</p>
    </div>
    <label for="" class="out-of-office-label">Preferred Accent</label>
    <v-select
      density="compact"
      flat
      class="mt-2 text-capitalize"
      variant="solo"
      :items="accents"
      v-model="callConfigStore.selectedAccent"
      :disabled="!switchToNewAccent"
      @update:menu="switchAccent"
      placeholder="Select Language"
    >
      <template v-slot:item="{ props, item }">
        <div class="p4-5 px-4 mt-1 pointer" v-bind="props">
          <p class="text-capitalize mb-3">{{ item.raw }}</p>
        </div>
      </template></v-select
    >
    <label for="" class="out-of-office-label">Voice</label>
    <v-select
      density="compact"
      flat
      class="mt-2"
      variant="solo"
      :items="filterVoicesByAccent"
      v-model="callConfigStore.selectedVoiceAccent"
      item-title="name"
      item-value="voice_id"
      :disabled="!switchToNewAccent"
      ><template v-slot:item="{ props, item, index }">
        <div class="pa-2" v-bind="props">
          <div class="d-flex justify-space-between">
            <p class="text-capitalize pointer d-flex align-center">
              <span class="accent-name">{{ item.raw.name }} </span>&nbsp<span
                class="accent-gender"
                >({{ item.raw.labels?.gender }})
              </span>
            </p>
            <audio
              :id="'audio_' + index"
              :src="item.raw.preview_url"
              type="audio/ogg"
            ></audio>
            <button
              @click.stop="handleAudio(index)"
              class="play-btn pa-1 d-flex justify-center"
            >
              <img
                :src="
                  isPlaying[index]
                    ? '/assets/Images/icons/record/pause-audio.svg'
                    : '/assets/Images/icons/record/play-audio.svg'
                "
                alt=""
                width="10"
              />
            </button>
          </div>
        </div> </template
    ></v-select>
    <div class="d-flex justify-end mt-5 mt-md-0 mb-5">
      <v-btn
        flat
        height="40"
        class="filled-primary-color py-3 px-4 mt-14 mt-md-0"
        @click.prevent="requestGreeting"
        :disabled="
          !callConfigStore.selectedVoiceAccent ||
          !callConfigStore.requestPayload.selectedIncomingTemplate
        "
        :block="smAndDown"
      >
        Generate audio
      </v-btn>
    </div>
  </v-form>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { VoiceAccent, messageRule } from "../../../../../helpers";
import Tooltip from "../../../../../components/Shared/Tooltip.vue";
import { useCallConfigStore } from "../../../../../stores/call-config.store";
import { notify } from "@kyvg/vue3-notification";
import { useDisplay } from "vuetify";

export default defineComponent({
  emits: ["requestGreeting"],
  components: { Tooltip },
  setup(_, { emit }) {
    const callConfigStore = useCallConfigStore();
    const form = ref<HTMLFormElement | null>(null);

    const switchToNewAccent = ref(true);
    const { smAndDown } = useDisplay();
    const accents = computed(() => {
      return [
        ...new Set(
          callConfigStore.accents?.map(
            (voice: VoiceAccent) => voice.labels.accent
          )
        ),
      ];
    });

    const filterVoicesByAccent = computed((): VoiceAccent[] | undefined => {
      return callConfigStore.accents?.filter(
        (voice: VoiceAccent) =>
          voice.labels.accent === callConfigStore.selectedAccent
      );
    });

    const requestGreeting = async () => {
      const { valid } = await form?.value?.validate();
      if (!valid) return;
      if (
        callConfigStore.requestPayload.selectedIncomingTemplate.trim() === ""
      ) {
        notify({
          text: "A greeting script is required to complete this action",
          type: "error",
        });
        return;
      }
      emit("requestGreeting", callConfigStore.selectedVoiceAccent);
    };

    const currentAudioIndex = ref<number | null>(null);
    const isPlaying = ref<boolean[]>([]);

    const handleAudio = (index: number) => {
      // play and pause audio

      if (
        currentAudioIndex.value !== null &&
        currentAudioIndex.value !== index
      ) {
        pauseAudio(currentAudioIndex.value);
      }
      const audio = document.getElementById(
        "audio_" + index
      ) as HTMLAudioElement;
      if (isPlaying.value[index]) {
        audio.pause();
      } else {
        audio.play();
        currentAudioIndex.value = index;
      }
      isPlaying.value[index] = !isPlaying.value[index];
    };

    const pauseAudio = (index: number) => {
      const audio = document.getElementById(
        "audio_" + index
      ) as HTMLAudioElement;
      if (audio) {
        audio.pause();
        isPlaying.value[index] = false;
      }
    };

    const switchAccent = () => {
      callConfigStore.selectedVoiceAccent = null;
    };
    const toggleTemplateNav = () => {
      callConfigStore.isOutOfoffice = false;
      callConfigStore.showTemplateNav = true;
    };
    return {
      filterVoicesByAccent,
      accents,
      requestGreeting,
      switchToNewAccent,
      messageRule,
      isPlaying,
      handleAudio,
      switchAccent,
      form,
      callConfigStore,
      toggleTemplateNav,
      smAndDown,
    };
  },
});
</script>
<style scoped>
.accent-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #101928;
}

.accent-gender {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
}
.v-select ::v-deep .v-field {
  border: 1px solid #e4e7ec !important;
  border-radius: 8px;
}
.v-select ::v-deep .v-select__selection-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  text-align: left;
  color: #101928;
}
</style>
