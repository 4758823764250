<template>
  <div
    class="tooltip-container"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot></slot>
    <div v-if="tooltipVisible" class="tooltip">
      <div class="arrow"></div>
      <slot name="tooltip"></slot>
    </div>
  </div>
</template>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  color: #1d2739;
  font-size: 12px;
  font-weight: 400;
  min-width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 17px;
  padding: 10px;
  border-radius: 8px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10%);
  z-index: 1000;

}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -10px;
  left: 52%;
  transform: translateX(-50%);
}
</style>

<script lang="ts">
export default {
  data() {
    return {
      tooltipVisible: false,
    };
  },
  methods: {
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
  },
};
</script>
