<template>
  <div>
    <v-toolbar color="#ffffff" class="border-b-sm px-2" v-if="!smAndDown">
      <v-toolbar-title>
        <div class="d-flex align-center">
          <img
            src="../../assets/Images/logos/pressone-logo-img.svg"
            alt=""
            width="35"
          />
          <span class="ml-2" style="font-size: 16px; font-weight: 500"
            >{{ title }}
          </span>
        </div></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="d-flex align-center">
          <v-btn
            prepend-icon="mdi-close"
            class="border-sm border-opacity-100 bg-white pa-2"
            rounded="lg"
            @click="$emit('closeModal')"
          >
            Close
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-toolbar>
    <div class="d-flex justify-space-between align-center mt-4 bg-white" v-else>
      <h3 class="px-4">{{ title }}</h3>
      <v-btn rounded="lg" @click="$emit('closeModal')" icon flat>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();

defineProps(["title"]);
defineEmits(["closeModal"]);
</script>