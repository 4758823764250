<template>
  <v-card
    :variant="!smAndDown ? 'outlined' : 'elevated'"
    :color="!smAndDown ? '#344054' : 'accent'"
    class="pa-4 pa-md-2"
    rounded="lg"
  >
    <div class="d-flex justify-space-between align-center" v-if="!hasFilled">
      <div>
        <p class="text-white ratings-text">Rate your call experience</p>
        <div class="d-flex ga-4 mt-2 cursor-default">
          <img
            :src="rating.img"
            alt=""
            class="pointer"
            v-for="(rating, index) in ratings"
            :key="index"
            @click="selectRating(rating.value)"
          />
        </div>
      </div>
      <v-icon color="white" size="md">mdi-close</v-icon>
    </div>
    <p class="text-white ratings-text" v-else>Thanks! for the feedback</p>
  </v-card>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  AMPLITUDE_EVENTS,
  trackAmplitudeEvent,
  engageTrack,
} from "../../helpers";
import { useDialerStore, useUsersStore, useUtilsStore } from "../../stores";
import { useDisplay } from "vuetify";
import { onMounted } from "vue";

const dialerStore = useDialerStore();
const utilsStore = useUtilsStore();
const userStore = useUsersStore();

const { smAndDown } = useDisplay();

const hasFilled = ref(false);
const ratings = ref<{ rating: string; img: string; value: number }[]>([
  {
    rating: "successful",
    img: "/assets/Images/icons/call-ratings/successful.svg",
    value: 5,
  },
  {
    rating: "unsuccessful",
    img: "/assets/Images/icons/call-ratings/unsuccessful.svg",
    value: 1,
  },
]);

const selectRating = (rating: number) => {
  trackAmplitudeEvent(AMPLITUDE_EVENTS.FIRST_CALL_RATINGS, { rating });
  trackAmplitudeEvent(AMPLITUDE_EVENTS.FIRST_CALL_RATING_COMPLETED, {
    "first_call_rating:": rating,
  });
  engageTrack(userStore.currentUserBusinessLevel?.mobile, "Made_First_Call");
  hasFilled.value = true;
  setTimeout(() => {
    dialerStore.showFeedback = false;
    utilsStore.totalCalls += 1;
    hasFilled.value = false;
  }, 3000);
};

onMounted(() => {
  trackAmplitudeEvent(AMPLITUDE_EVENTS.FIRST_CALL_RATING_VIEWED);
});
</script>
<style scoped>
.ratings-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20.3px;
}
</style>
