import { PINIA_PERSIST_OPTIONS } from "./../helpers/constants";
import { defineStore } from "pinia";
import { OnboardingList, getListOfOnboardingStage } from "../helpers";
import { ScriptInfo } from "../helpers/types/callScript";
import { VoiceAccent } from "../helpers";
import { useBusinessStore, useNumberStore, useUtilsStore } from "./";

type Statuses = {
  title: string;
  subtitle: string;
  buttonTitle: string;
  img: string;
  value?: string;
  to?: string;
};
interface PersonalizationState {
  userOnboardingStatus: OnboardingList | any;
  callScript: ScriptInfo | any;
  openManageScriptDrawer: boolean;
  accents: VoiceAccent[] | null;
  greetingActivatingProgress: number;
  isCompletingSetupFlow: boolean;
  welcomeGreetingList: any;
  teamMembers?: any;
  isPublishingAudio: boolean;
  isPublished: boolean;
}
export const usePersonalizeStore = defineStore({
  id: "personalization",
  state: (): PersonalizationState => ({
    userOnboardingStatus: null,
    callScript: {},
    openManageScriptDrawer: false,
    accents: null,
    greetingActivatingProgress: 0,
    isCompletingSetupFlow: false,
    welcomeGreetingList: [],
    teamMembers: [],
    isPublishingAudio: false,
    isPublished: false,
  }),
  getters: {
    orderedStatus(state): any {
      const { activeBusiness } = useBusinessStore();
      return this.statuses.map((status: any) => ({
        ...status,
        completed:
          status.value === "verify_nin" // check for nin verification through user object
            ? !activeBusiness?.business.verified
              ? false
              : true
            : state.userOnboardingStatus?.[status.value] || false,
      }));
    },
    completedTasksCount(state): number {
      if (state.userOnboardingStatus) {
        return this.orderedStatus?.filter(
          (item: any) => item.completed === true
        ).length;
      } else return 0;
    },

    statusLength(): number {
      return this.orderedStatus.length ?? 0;
    },
    completedTaskPercentage(): number {
      return (this.completedTasksCount / this.statusLength) * 100;
    },
    statuses(): Statuses[] {
      return [
        {
          title: "Verify your identity",
          subtitle: `We need to make sure of your identity to use our service.${!useNumberStore().requiresNINVerification
            ? " You will need your NIN and a valid ID"
            : ""
            }`,
          buttonTitle: "Verify",
          img: "/assets/Manager/setup-status/verify.svg",
          to: "/managers/profile-update",
          value: "verify_nin",
        },
        ...(!useUtilsStore().isSingleGoGlobalUser
          ? [
            {
              title: "Invite your team",
              subtitle:
                "Invite everyone that needs to make or receive calls.",
              buttonTitle: "Invite team",
              img: "/assets/Manager/setup-status/invite-team.svg",
              value: "has_invited_team_member",
              to: "/managers/team",
            },
          ]
          : []),
        ...(useBusinessStore().activeProfile.business.is_go_global
          ? []
          : [
            {
              title: "Personalize your incoming call flow",
              subtitle:
                "Determine how you want your incoming calls to be handled.",
              buttonTitle: "Set up",
              img: "/assets/Manager/setup-status/call-flow.svg",
              to: "/managers/incoming-flow",
              value: "has_set_incoming_call_flow",
            },
          ]),

        {
          title: "Make and receive a call",
          subtitle:
            "Get started on PressOne today. Make and receive a call to and from anyone respectively.",
          buttonTitle: "Make a Call",
          img: "/assets/Manager/setup-status/test-call.svg",
          value: "has_made_call",
        },

        {
          title: "Watch a tour of the system",
          subtitle:
            "We have provided you with a short guide on how to use our system. Check it out now to get the best experience.",
          buttonTitle: "Watch Tour",
          img: "/assets/Manager/setup-status/tour.svg",
          value: "has_watched_tour",
        },
      ];
    },
  },
  actions: {
    clearStore() {
      this.isCompletingSetupFlow = false;
      this.isPublishingAudio = false;
      this.isPublished = false;
    },
    async fetchOnboardingStatus() {
      try {
        const response = await getListOfOnboardingStage();
        this.userOnboardingStatus = response;
      } catch (error) {

      }

    },
  },

  persist: PINIA_PERSIST_OPTIONS,
});
