import { BASE_API } from "../../constants";
import { getActiveBusinessId } from "../..";
import requestClient from "../../api/requestClient";

interface ResponseData {
    [key: string]: any;
}
//

export const fetchLiveWidgetCustomization = async (): Promise<ResponseData> => {
    // get number under a business
    return await requestClient.get(
        `${BASE_API}/api/business/${getActiveBusinessId()}/addons/live-widget/setting/`
    );
};
export const updateLiveWidgetCustomization = async (
    payload: any
): Promise<ResponseData> => {
    // get number under a business
    return await requestClient.patch(
        `${BASE_API}/api/business/${getActiveBusinessId()}/addons/live-widget/setting/`,
        { json: payload }

    );
};

export const removeLiveWidget = async (
    id: number | string
): Promise<ResponseData> => {
    // get number under a business
    return await requestClient.delete(
        `${BASE_API}/api/addons/${getActiveBusinessId()}/remove/${id}/`
    );
};

export const getSummary = async (
    period: string,
    startDate?: string,
    endDate?: string
): Promise<ResponseData> => {
    const businessId = getActiveBusinessId();
    let url = `${BASE_API}/api/business/${businessId}/addons/live-widget/call-summary/?period=${period}`;

    if (startDate) {
        url += `&custom_start_date=${startDate}`;
    }

    if (endDate) {
        url += `&custom_end_date=${endDate}`;
    }

    return await requestClient.get(url);
};

export const fetchApiToken = async (): Promise<ResponseData> => {
    let url = `${BASE_API}/api/business/${getActiveBusinessId()}/api-keys/`;
    return await requestClient.get(url);
};
export const createApiToken = async (): Promise<ResponseData> => {
    let url = `${BASE_API}/api/business/${getActiveBusinessId()}/api-keys/`;
    const payload = {
        otp: "string",
        type: "public_key",
        expiry: -1,
    }
    return await requestClient.post(url, { json: payload });
};
