import { BASE_API } from "../constants";
import requestClient from "../api/requestClient";

interface ResponseData {
    [key: string]: any;
}

export const updateDeveloperProfile = async (
    payload: any
): Promise<ResponseData> => {
    let url = `${BASE_API}/api/third-party/developer/register/`;
    return await requestClient.post(url, { json: payload });
};

export const getDeveloperProfile = async (): Promise<ResponseData> => {
    let url = `${BASE_API}/api/third-party/developer/me/`;
    return await requestClient.get(url);
};
