<template>
  <div>
    <onboard-title
      title="Set up your caller greetings"
      @close-modal="$router.push('/managers/initial-user-onboarding')"
    ></onboard-title>
    <p class="availability-time px-4 py-3">
      This is what your customers hear when they call your number, before it
      rings.
    </p>
    <v-divider></v-divider>

    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        variant="plain"
        @click="
          callConfigStore.useAudioPromptForWelcomeGreeting =
            !callConfigStore.useAudioPromptForWelcomeGreeting
        "
        v-if="smAndDown"
        class="text-right"
        >{{
          callConfigStore.useAudioPromptForWelcomeGreeting
            ? "Add a text prompt instead"
            : "Upload an audio prompt instead"
        }}</v-btn
      >
    </div>
    <div class="out-of-office-action-screens">
      <!-- List of Current calls -->
      <div class="ma-3">
        <template v-if="callConfigStore.useAudioPromptForWelcomeGreeting">
          <AudioUpload
            @saveAudioInfo="saveAudioInfo"
            :audioInfo="callConfigStore.welcomeGreetingUploadedInfo"
            @deleteAudio="callConfigStore.welcomeGreetingUploadedInfo = null"
            audio_source="WELCOME_PROMPT"
            class="mt-10"
          />
          <v-btn
            flat
            color="primary"
            class="filled-primary-color audio-proceed-btn"
            height="45"
            :disabled="
              callConfigStore.isUploadingAudioScript ||
              callConfigStore.isRetrievalError
            "
            @click="completeSetup"
            :loading="callConfigStore.isPublishingAudio"
            >Publish To Network</v-btn
          >
        </template>

        <template v-else>
          <Settings
            v-if="
              !callConfigStore.isGeneratingWelcomeAudio &&
              !callConfigStore.hasGeneratedWelcomeAudio
            "
            @requestGreeting="requestGreeting"
          />
          <GeneratingAudio
            v-else-if="
              callConfigStore.isGeneratingWelcomeAudio &&
              !callConfigStore.hasGeneratedWelcomeAudio
            "
          />
          <GeneratedAudio
            v-else
            @nextStep="handleNextStep"
            @edit-flow="$emit('editFlow')"
          />
        </template>
      </div>
    </div>
    <!-- </template> -->
  </div>
  <MobileDevicePrompt />
</template>
  <script lang="ts" setup>
import { onMounted } from "vue";
import Settings from "./CallerGreetings/Settings.vue";
import GeneratingAudio from "./CallerGreetings/GeneratingAudio.vue";
import GeneratedAudio from "./CallerGreetings/GeneratedAudio.vue";
import { notify } from "@kyvg/vue3-notification";
import {
  GreetingToSave,
  getProfessionalGreetingsList,
  requestForGreeting,
  useCallConfig,
  useTeamMember,
} from "../../../../helpers";
import {
  useCallConfigStore,
  useNumberStore,
  useTeamStore,
} from "../../../../stores";
import logger from "../../../../helpers/logger";
import OnboardTitle from "../../../../components/Shared/OnboardTitle.vue";
import MobileDevicePrompt from "../../../../components/Shared/MobileDevicePrompt.vue";
import AudioUpload from "./AudioUpload.vue";
import { useDisplay } from "vuetify";

const emit = defineEmits(["nextStep", "editFlow"]);
const callConfigStore = useCallConfigStore();
const teamStore = useTeamStore();

const { listTeamMembers } = useTeamMember;

const { smAndDown } = useDisplay();

const { completeSetup } = useCallConfig();

const { activeAllBusinessNumberId } = useNumberStore();
const getWelcomeGreetings = async () => {
  try {
    const greetings = await getProfessionalGreetingsList(
      // get only the script of the one just created by passing the uuid
      "WELCOME_PROMPT",
      callConfigStore.currentIncomingSetGreeting?.uuid
    );
    callConfigStore.getCurrentWelcomeAudio = greetings.data[0];
  } catch (error) {
    logger.error(error, "getWelcomeGreetings");
  }
};
const requestGreeting = async (voice_accent: string) => {
  callConfigStore.isGeneratingWelcomeAudio = true;
  callConfigStore.greetingActivatingProgress = 0;
  try {
    const payload: GreetingToSave = {
      text: callConfigStore.requestPayload.selectedIncomingTemplate,
      greeting_type: "WELCOME_PROMPT",
      voice_accent,
    };
    callConfigStore.greetingActivatingProgress = 50;
    const requestGreetingResponse = await requestForGreeting(payload);
    callConfigStore.currentIncomingSetGreeting = requestGreetingResponse;
    callConfigStore.greetingActivatingProgress = 60;
    callConfigStore.greetingActivatingProgress = 75;
    setTimeout(async () => {
      callConfigStore.greetingActivatingProgress = 100;
      await getWelcomeGreetings();
      callConfigStore.isGeneratingWelcomeAudio = false;
      callConfigStore.hasGeneratedWelcomeAudio = true;
    }, 30000); // delay due to the latency. call incoming doesnt update immediately.takes time
  } catch (error: any) {
    callConfigStore.isGeneratingWelcomeAudio = false;
    notify({
      text: `${error?.message || error}`,
      type: "error",
    });
  }
};

const handleNextStep = (component: string) => {
  emit("nextStep", component);
};
const saveAudioInfo = (audioObject: File) => {
  callConfigStore.welcomeGreetingUploadedInfo = {
    ...callConfigStore.welcomeGreetingUploadedInfo,
    ...audioObject,
  };
};
onMounted(async () => {
  callConfigStore.clearStore();
  await callConfigStore.getAccentLists();
  teamStore.teamMembers = await listTeamMembers();
  callConfigStore.requestPayload.call_forwarding_line =
    teamStore.teamMembers?.find((item) => item?.role === "owner")?.mobile;
  callConfigStore.requestPayload.name = "Default Greeting";
  callConfigStore.teamMembersManage = teamStore.teamMembers;
  callConfigStore.businessNumbersToAssignFlow = {
    business_numbers: [Number(activeAllBusinessNumberId)], // assign the only business number that exists
  };
});
</script>
  <style scoped>
.select-time-field,
.v-select .v-field.v-field {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 0px 0px 1px #464f6029;
  box-shadow: 0px 1px 1px 0px #0000001a;
  border-radius: 8px;
}
.v-select ::v-deep .v-field {
  border: 1px solid #e4e7ec !important;
  border-radius: 8px;
}
.v-select ::v-deep .v-select__selection-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  text-align: left;
  color: #101928;
}
.v-selection-control ::v-deep .v-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: black !important;
  opacity: 1;
}
.audio-proceed-btn {
  position: absolute;
  bottom: 10px;
  width: 95%;
}
</style>
  