import { defineStore } from "pinia";
import { allCallData, CallInfo } from "../helpers/types/call";
import { Contacts, PINIA_PERSIST_OPTIONS } from "../helpers";
import { useTeamStore } from "./team-member.store";
import { useUsersStore } from "./users.store";
interface CallState {
    currentRequestForReload: string | null;
    selectedCallInfo: CallInfo | any;
    selectedCallContactDetails: Contacts | any;
    successSave: boolean;
    selectedIndex: number | null;
    callList?: allCallData | null;
    currentRequestQuery: string | null;
    pageIndex: null | number | undefined;
    showCallNotes: boolean;
    voiceMessagesList: any;
    callEventType: string;
    toDate: string;
    fromDate: string;
    contactToSearch: string;
    transientCallRecord: any;
    totalLogs: number | string;
    fetchFilter: boolean;
    sortedData: any;
    saveContactFromTable: boolean
    showCallNotesCard: boolean
    showRecordsDetailsMenu: boolean // alot of components will be using this
    isFetchingDetails: boolean
    selectedTeamMemberLog: number | null
    showCallNoteList: boolean
    openCallInbox: boolean
}
export const useCallStore = defineStore({
    id: "calls",
    state: (): CallState => ({
        currentRequestForReload: "",
        selectedCallInfo: "",
        selectedCallContactDetails: {},
        successSave: false, // when you save succesfully in callrecord,
        selectedIndex: null,
        pageIndex: null,
        currentRequestQuery: null,
        showCallNotes: false,
        voiceMessagesList: null,
        callList: null,
        callEventType: "",
        fromDate: "",
        toDate: "",
        contactToSearch: "",
        transientCallRecord: null,
        totalLogs: 0, // tracks changes in logs
        fetchFilter: false,
        sortedData: null,
        saveContactFromTable: false,
        showCallNotesCard: false,
        showRecordsDetailsMenu: false,
        isFetchingDetails: false,
        selectedTeamMemberLog: null,
        showCallNoteList: false,
        openCallInbox: false
    }),
    getters: {
        getNextUrl: (state) => {
            const urlParams = new URLSearchParams(
                state.currentRequestForReload?.split("?")[1]
            ); // Get the query parameters
            urlParams.set("page_index", (state.pageIndex! + 1).toString()); // Set the updated page index
            const baseUrl = state.currentRequestForReload?.split("?")[0]; // Get the base URL
            return `${baseUrl}?${urlParams.toString()}`;
        },
        prevNextUrl: (state) => {
            const urlParams = new URLSearchParams(
                state.currentRequestForReload?.split("?")[1]
            ); // Get the query parameters
            urlParams.set("page_index", (state.pageIndex! - 1).toString()); // Set the updated page index
            const baseUrl = state.currentRequestForReload?.split("?")[0]; // Get the base URL
            return `${baseUrl}?${urlParams.toString()}`;
        },
        getNewRecordNotification: (state) => {
            if (!state.voiceMessagesList || !state.voiceMessagesList?.length)
                return false;

            return !!state.voiceMessagesList?.find(
                (message: any) => !message.is_played
            );
        },
        getSelectedCallTeamMemberInfo: (state) => {
            const teamMemberStore = useTeamStore()
            const userStore = useUsersStore()
            if (userStore.user_role !== 'agent') return teamMemberStore.teamMembers.find(teamMember => teamMember.user === state.selectedTeamMemberLog)
        }
    },
    persist: PINIA_PERSIST_OPTIONS,
});
