import { getActiveBusinessId } from "../../queries";
import { BASE_API } from "../../constants";

import requestClient from "../../api/requestClient";

export const getAllAddonList = async (term: string = "monthly") => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/plans/?term=${term}`
  );
};

export const getSubscribedAddonList = async () => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/`
  );
};

export const subscribeToAddon = async (payload: any) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/`,
    { json: payload }
  );
};

export const unsubscribeToAddon = async (addon_sub_id: number) => {
  return await requestClient.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/${addon_sub_id}/`
  );
};
