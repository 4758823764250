<template>
    <div class="d-flex justify-space-between align-center ga-2">
      <img
        :src="
          isPlaying
            ? '/assets/Images/icons/record/pause-audio.svg' // replace with pause icon later
            : '/assets/Images/icons/record/play-audio.svg'
        "
        :title="isPlaying ? 'Pause' : 'Play'"
        @click="toggleAudio"
        class="pointer"
        width="18"
      />
      <p class="audio-duration">
              {{ formatAudioDuration(playTime) }}/{{
                formatAudioDuration(durations)
              }}
            </p>      <audio
        ref="audioElement"
        :src="audioFile"
        @ended="isPlaying = false"
        @loadedmetadata="onAudioLoad"
      ></audio>
      <!-- <av-waveform
        :src="audioFile"
        :audio-controls="false"
        :canv-width="300"
        :playtime="false"
        canv-height="40"
        ref="waveformElement"
      ></av-waveform> -->
      <!-- <wavesurfer :src="audioFile"></wavesurfer> -->
      <div
        class="mt-1 d-flex justify-space-between w-75"
        @click.prevent="toggleAudio"
      >
        <div
          class="bar"
          v-for="(v, k) in Array.from({ length: 98 }, (_, i) => i + 1)"
          :key="k"
        >
          <div
            v-if="durations"
            :class="`bar-indicator ${
              Math.ceil((v / 98) * durations) < playTime ? 'active' : ''
            }`"
            :style="barStyle()"
          ></div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, defineComponent } from "vue";
import { formatAudioDuration } from "../../../helpers";
  
  export default defineComponent({
    name: "AudioBar",
    components: {},
    props: {
      audioFile: String,
      audioDuration: Number || undefined,
    },
    setup() {
      const play = ref<boolean>(false);
      const isPlaying = ref<boolean>(false);
      const audioElement = ref<HTMLAudioElement | null>(null);
      const playTime = ref(0);
      const counter = ref<any>(null);
      const durations = ref<number>(0);
        const currentTime = ref<number>(0);
      const onAudioLoad = () => {
        durations.value = Math.ceil(audioElement?.value?.duration!);
        currentTime.value = audioElement.value?.currentTime || 0;

      };
      const toggleAudio = () => {
        if (isPlaying.value) {
          audioElement?.value?.pause();
          clearInterval(counter.value!);
        } else {
          audioElement?.value?.play();
          counter.value = setInterval(() => {
            if (playTime.value >= durations.value!) {
              playTime.value = 0;
              play.value = false;
              clearInterval(counter.value!);
            }
            playTime.value += 1;
          }, 1000);
        }
        isPlaying.value = !isPlaying.value;
      };
  
      const barStyle = () => {
        return {
          height: `${Math.floor(Math.random() * 100) + 30}%`,
        };
      };
      const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
          .toString()
          .padStart(2, "0")}`;
      };
      return {
        play,
        isPlaying,
        audioElement,
        toggleAudio,
        playTime,
        barStyle,
        counter,
        onAudioLoad,
        durations,
        formatTime,
        formatAudioDuration,
        currentTime
      };
    },
  });
  </script>
  <style>
  .bar {
    height: 45px;
    width: 1px;
    background-color: inherit;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  /* */
  .bar-indicator {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-color: #98A2B3;
    transition: height 0.5s;
  }
  .bar-indicator.active {
    background-color: black;
  }
  .timer {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1d2739;
  }
  </style>
  