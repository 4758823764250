export enum AMPLITUDE_EVENTS {
  // Onboarding Events
  CLICKED_SIGNUP_BTN = "Clicked Sign up button",
  BRASS_USER_SIGNUP = "Viewed Signup through Brass link",
  SCALEIN_USER_SIGNUP = "Viewed Signup through ScaleIn link",
  STERLING_USER_SIGNUP = "Viewed Signup through Sterling link",
  PRINTIVO_USER_SIGNUP = "Viewed Signup through Printivo link",
  SIDE_BRIEF_USER_SIGNUP = "Viewed Signup through Sidebrief link",
  AFFILIATE_SIGNUP = "Viewed Signup through Affiliate Referral",
  // Sign in events
  VIEWED_SIGN_IN = "Viewed Signin page",
  CLICKED_SIGNIN_BTN = "Clicked Signin",
  LOGIN = "User Signedin",
  WINBACK_CAMPAIGN_SIGNIN = "Winback Campaign Signin",

  //   Call Queue
  START_CALL_QUEUE = "Started Call Queue",
  PAUSE_CALL_QUEUE = "Paused Call Queue",
  RESUME_CALL_QUEUE = "Resumed Call Queue",
  // Recommended Plan Events
  VIEWED_RECOMMENDED_FORM = "Viewed Recommendation Form",
  VIEWED_RECOMMENDED_PLAN = "Viewed Recommended Plan",
  // SELECTED_SOLO_PLAN = "Selected Plan: Solo ",
  SELECTED_STANDARD_PLAN = "Selected Plan: Standard ",
  SELECTED_GROWTH_PLAN = "Selected Plan: Growth ",
  SELECTED_INTERNATIONAL_PLAN = "Selected Plan: International ",
  // SELECTED_SOLO_QUARTERLY_PLAN = "Selected Plan: Solo Quaterly",
  SELECTED_STANDARD_QUARTERLY_PLAN = "Selected Plan: Standard Quaterly",
  SELECTED_GROWTH_QUARTERLY_PLAN = "Selected Plan: Growth Quaterly",
  SELECTED_INTERNATIONAL_QUARTERLY_PLAN = "Selected Plan: International Quaterly",
  // SELECTED_SOLO_ANNUAL_PLAN = "Selected Plan: Solo Anual",
  SELECTED_STANDARD_ANNUAL_PLAN = "Selected Plan: Standard Anual",
  SELECTED_GROWTH_ANNUAL_PLAN = "Selected Plan: Growth Anual",
  SELECTED_INTERNATIONAL_ANNUAL_PLAN = "Selected Plan: International Anual",
  // Subscription
  CLICKED_PAYMENT_BTN = "Clicked Payment button",
  // Number Setup
  VIEWED_NUMBER_SETUP = "Viewed number setup",
  CLICKED_PURCHASE_ADDITIONAL_NUMBER = "Clicked purchase additional number",
  COMPLETED_SETUP_FLOW = "Completed Setup Flow", // donne
  VIEW_AVAILABLE_NUMBER = "View Available numbers",
  SELECT_NUMBER = "Select number",
  // Onboarding
  CLICKED_TEST_CALL = "Clicked Test call",
  TEST_CALL_SUCCESSFUL = "Test call successful",
  TEST_CALL_FAILED = "Test call failed",
  CLICKED_WATCH_TOUR = "Clicked Watch Tour",
  CLICKED_INVITE_TEAM_MEMBER = "Clicked Invite Team Member",
  CLICKED_VERIFY_ACCOUNT = "Clicked Verify Account",
  COMPLETED_ACCOUNT_VERIFICATION = "Completed account verification",
  CLICKED_INCOMING_CALL_FLOW = "Clicked Incoming call flow",
  CLICKED_OUT_OF_OFFICE_FLOW = "Clicked Out-of-office flow",
  //   CALL
  OPEN_DIALLER = "Open dialler",
  CLOSED_DIALLER = "Close dialler",
  SIP_CONNECTED = "Sip connected",
  SIP_DISCONNECTED = "Sip disconnected",
  SIP_CONNECTION_ERR = "Sip connection error",
  CALL_TRANSFERRED = "call transfered",
  CALL_MUTED = "call muted",
  CALL_HELD = "call on hold",
  //   Billing
  UPGRADED_SUBSCRIPTION = "Click Upgrade Subscription",
  CANCELLED_SUBSCRIPTION = "Click Cancel Subscription",
  CLICK_CANCEL_SUB = "click cancel subscription",
  //   INTEGRATIONS
  CLICK_CONNECT_TO_ZOHO_CRM = "Click connect to Zohocrm",
  CLICK_CONNECT_TO_HUBSPOT_CRM = "Click connect to Hubspot",
  CLICK_CONNECT_TO_FRESHDESK = "Click connect to Freshdesk",
  CLICK_CONNECT_TO_GOOGLE_CHROME_EXTENSION = "Click connect to Google Chrome Extension",
  CLICK_CONNECT_TO_PIPE_DRIVE = "Click connect to Pipe Drive",
  CLICK_CONNECT_TO_ZOHO_PHONE_BRIDGE = "Click connect to Zoho Phone Bridge",
  CLICK_CONNECT_TO_INTERCOM = "Click connect to Intercom",
  CLICK_CONNECT_TO_PRESSONE_WIDGET = "Click connect Pressone Widget",
  CLICK_CONNECT_TO_ZAPIER = "Click connect to Zapier",
  //   Personalization
  CREATED_TAG = "Created tag",
  CREATED_CALL_SCRIPT = "Created call script",
  WGH_REFERALL_SIGNUP = "New WGH referral signup",
  PRESSONE_WEBSITE_SIGNUP = "New PressOneWebsite signup",
  // Feedbck
  NEW_EXPERIENCE_FEEBACK_SUBMITTED = "New experience feedback submitted",
  HEARD_ABOUT_US = "Where did you hear about us",

  //Business Usecase on Complete SignUp
  BUSINESS_USE_CASE = "What best describes how you will use PressOne?",

  // Share provisioned number
  SHARE_NUMBER = "share-number",
  SHARE_ADDRESS = "share-address",
  CLICK_REACTIVATE_SUBSCRIPTION = "Click reactivate subscription",
  RENEW_SUBSCRIPTION = "Click renew subscription",
  FIRST_CALL_RATINGS = "First Call Rating",
  VIEWED_GO_GLOBAL_PRICING = "Viewed Go-global pricing",
  CLICKED_BUY_NUMBER = "Clicked buy number",
  CLICKED_BUY_ADDRESS = "Clicked buy address",
  CLICKED_BUY_NUMBER_ADDRESS = "Clicked buy number-address",
  VIEWED_ORDER_REVIEW = "Viewed order review",
  CLICKED_MAKE_PAYMENT = "Clicked make payment",

  // Call recording events
  CALL_RECORDING_SHARE = "call recording share",
  CALL_RECORD_SHARE = "call records share",
  CALL_NOTE_SAVED = "call note added",
  CALL_RECORD_DOWNLOAD = "call record download",
  CALL_RECORD_PLAYED = "call recording played",
  PLAYING_CALL_RECORDING = "Play recording",
  OPEN_CALL_DETAILS = "open call details",
  OPEN_CALL_RECORD = "Open call records",
  CLICK_EXPORT_CSV = "click export csv",
  EXPORTED_CSV = "exported csv",
  FILTER_CALL_DURATION = "filter by call duration",
  // Hangup events
  HANGUP = "hangup", // (after call is picked)
  HANGUP_REJECTED = "hangup_rejected", // (if it's inbound and they chose not to pick it)
  HANGUP_BEFORE_SESSION_STARTS = "hangup_before_session_starts",
  // Card tracking
  CLICKED_UPDATE_CARD = "click update card",
  // Network
  CLICKED_NETWORK_BAR = "click network bar",
  VIEW_NETWORK_DETAILS = "view network details",
  // IVR
  CREATE_A_MENU_OPTION = "Create menu option",
  SAVE_IVR = "Save ivr",

  //  Addons
  CLICKED_SALES_CAMPAIGN_ADDON_SUBSCRIBE_BUTTON = "Clicked Sales Campaign Addon Subscribe Button",
  SUBSCRIBED_SALES_CAMPAIGN_ADDON = "Subscribed Sales Campaign Addon",
  CLICKED_SALES_CAMPAIGN_ADDON_CREATE_BUTTON = "Clicked Sales Campaign Addon Create Button",
  SAVED_CREATED_CAMPAIGN = "Saved Created Campaign",
  VIEWED_SALES_CAMPAIGN_ADDON_CAMPAIGN_REPORT = "Viewed Sales Campaign Addon Campaign Report",
  EXPORT_SALES_CAMPAIGN_ADDON_CAMPAIGN_REPORT = "Export Sales Campaign Addon Campaign Report",

  //Conversations

  CLICKED_CREATE_CONVERSATION = "Click create a conversation",
  CLICKED_EDIT_CONVERSATION_TITLE = "Click edit conversation title",
  CLICKED_INVITE_TEAM_MEMBER_TO_CONVERSATION = "Click invite a team member",
  CLICKED_INVITE_CUSTOMER = "Click invite a customer",
  CLICKED_CLOSE_CONVERSATION = "Click close a conversation",
  CLICKED_LEAVE_CONVERSATION = "Click leave a conversation",
  CLICKED_SAVE_CUSTOMER_DETAILS = "Click save customer details",
  CLICKED_UPLOAD_FILE_TO_CONVERSATION = "Click upload a file to a conversation",
  CLICKED_ADD_NOTE_TO_CONVERSATION = "Click add a call note to a conversation",
  CLICKED_MERGE_CONVERSATION = "Click to merge conversations",
  //  New Authentication
  // Open signup page (fe)

  OPENED_SIGNUP_PAGE = "Opened Signup Page",
  OPENED_SIGNIN_PAGE = "Opened Signin Page",
  SELECTED_OTP_OPTION = "Selected OTP Option",
  OPENED_FORGOT_PASSWORD = "Opened Forgot Password",
  OPENED_RESET_PASSWORD = "Opened Reset Password",

  // New amplitude events
  ONBOARDING_PAGE_VIEWED = "onboarding_page_viewed",
  MAKE_FIRST_CALL_BUTTON_CLICKED = "make_first_call_button_clicked",
  FIRST_CALL_RATING_VIEWED = "first_call_rating_viewed",
  FIRST_CALL_RATING_COMPLETED = "first_call_rating_completed",
  VERIFICATION_BUTTON_CLICKED = "verification_button_clicked",
  VERIFICATION_DOCUMENTS_SUBMITTED = "verification_documents_submitted",
  MANUAL_VERIFICATION_CLICKED = "manual_verification_clicked",
  ADD_TEAM_MEMBER_CLICKED = "add_team_member_clicked",
  GREETINGS_SETUP_CLICKED = "greetings_setup_clicked",
  GREETINGS_RATING_VIEWED = "greetings_rating_viewed",
  GREETINGS_RATING_COMPLETED = "greetings_rating_completed",
  DOWNLOAD_MOBILE_APP_VIEWED = "download_mobile_app_viewed",
  DOWNLOAD_MOBILE_APP_CLICKED = "download_mobile_app_clicked",
  WALKTHROUGH_GUIDE_CLICKED = "walkthrough_guide_clicked",
  WALKTHROUGH_GUIDE_COMPLETED = "walkthrough_guide_completed",
  CUSTOMER_ONBOARDING_FEEDBACK_SURVEY_VIEWED = "customer_onboarding_feedback_survey_viewed",
  CUSTOMER_ONBOARDING_FEEDBACK_SURVEY_COMPLETED = "customer_onboarding_feedback_survey_completed",
  CONTACT_INFO_SUBMITTED = "contact_info_submitted",
  BUSINESS_INFO_SUBMITTED = "business_info_submitted",
  INVOICE_VIEWED = "invoice_viewed",
  PAYMENT_ATTEMPTED = "payment_attempted",
  PAYMENT_COMPLETED = "payment_completed",
}
