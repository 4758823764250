import { ResponseData } from "..";
import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";

export const fetchAllVideo = async (
    role: string,
    link?: string
): Promise<ResponseData> => {
    if (link) return await requestClient.get(link);
    else
        return await requestClient.get(
            `${BASE_API}/api/user/demo-video/?visibility=general,${role}`
        );
};
export const searchDemoVideo = async (
    role: string,
    searchParam: string
): Promise<ResponseData> => {
    return await requestClient.get(
        `${BASE_API}/api/user/demo-video/?visibility=general,${role}&title=${searchParam}`
    );
};
