import { BASE_API } from "../constants";
import { TabFilterItemTypes, createContactType, BulkCreateContact } from "../types/contact";
import { getActiveBusinessId } from "../queries";
import requestClient from "../api/requestClient";

interface ResponseData {
    [key: string]: any;
}

export const getContacts = async (
    business_id: string | number,
    link?: string
): Promise<ResponseData> => {
    if (link) {
        return await requestClient.get(link);
    } else
        return await requestClient.get(
            `${BASE_API}/api/business/${business_id}/contacts/?page_index=1&page_size=100&ordering=name`
        );
};

export const getContact = async (
    business_id: string | number,
    contact_id: any
): Promise<ResponseData> => {
    return await requestClient.get(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`
    );
};

export const searchContacts = async (
    payload: string,
    business_id: string | number
): Promise<ResponseData> => {
    return await requestClient.get(
        `${BASE_API}/api/business/${business_id}/contacts/?search=${payload}&ordering=name`
    );
};
export const filterContacts = async (
    payload: TabFilterItemTypes,
    business_id: string | number,
    orderingType: string,
    tagsFilter?: boolean): Promise<ResponseData> => {
    const baseUrl = `${BASE_API}/api/business/${business_id}/contacts/?page_size=50&ordering=${orderingType}`;

    // Update Url if filter by tags is selected
    const url = tagsFilter
        ? `${baseUrl}&tags=${payload}`
        : `${baseUrl}&filter=${payload}`;

    return await requestClient.get(url);
};
export const createContact = async (
    payload: createContactType,
    id: string | number
): Promise<ResponseData> => {
    return await requestClient.post(
        `${BASE_API}/api/business/${id}/contacts/`,
        { json: payload }
    );
};

export const editContact = async (
    contact_id: number,
    payload: any,
    business_id: string | number
): Promise<ResponseData> => {
    return await requestClient.patch(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`,
        { json: payload }
    );
};

export const getContactCsv = async (): Promise<any> => {
    return await requestClient.download(
        `${BASE_API}/api/business/${getActiveBusinessId()}/contacts/export-csv/`,
        { blobType: 'text/csv' },
        "contacts.csv"
    );
};
export const deleteContact = async (
    contact_id: any,
    business_id: string | number
): Promise<ResponseData> => {
    return await requestClient.delete(
        `${BASE_API}/api/business/${business_id}/contacts/${contact_id}/`
    );
};

export const bulkDelete = async (
    business_id: string | number,
    contact_ids: Array<number>
) => {
    return await requestClient.post(
        `${BASE_API}/api/business/${business_id}/contacts/bulk-delete/`,
        { json: { contact_ids: contact_ids } }
    );
};
export const sendContactCsv = async (
    business_id: string | number,
    payload: BulkCreateContact
) => {
    return await requestClient.post(
        `${BASE_API}/api/business/${business_id}/contacts/bulk-create/`,
        { json: payload }
    );
};

export const getContactLogs = async (id: any, phonenumber: any) => {
    return await requestClient.get(
        `${BASE_API}/api/telephony/calls/?number=${id}&phone_number=${phonenumber}&page_size=10`
    );
};
