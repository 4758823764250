import { useTeamStore, useCallConfigStore, useBusinessStore, usePersonalizeStore, useUsersStore, useUtilsStore } from "../stores";
import logger from "./logger"
import { assignNumbers, createCallConfig, fetchCallConfig } from "./queries/call-config"
import { v4 as uuidv4 } from "uuid";
import { callOptionType, Configuration, ConfigureMenuPayload, Entries, MenuEntryPayload, MenusGreeting, OptionSection, RingGroup } from "./types/call-config";
import { TeamMember } from "./types/teams";
import { notify } from "@kyvg/vue3-notification";
import { trackAmplitudeEvent } from "./integrations/analytics/amplitude";
import { AMPLITUDE_EVENTS } from "./integrations/analytics/events";
import { updateOnboardingStage } from "./queries/personalization";
import { engageTrack } from "./integrations/analytics/engage";
import { ref } from "vue";
import { isNigerianNumber, validateMobile } from "./utils";
import router from "../router";
import { getProfessionalGreetingsList } from "./personalization";
import { ResponseError } from "./types/error";

export const getCallFlows = async (link?: string, id?: string) => {
  try {
    const response = await fetchCallConfig(link, id)
    return response
  } catch (error: any) {
    logger.error(error, 'Get all call configuration')
  }
}
export const getGreetingsById = async (type: string | undefined = undefined, uuid: string) => {
  return await getProfessionalGreetingsList(type, uuid);
}
export function useCallConfig() {

  const teamStore = useTeamStore();
  const callConfigStore = useCallConfigStore()


  const { currentUserId } = useBusinessStore();
  const personalizeStore = usePersonalizeStore();
  const utilStore = useUtilsStore()

  const userStore = useUsersStore()


  const callOptionTypes: callOptionType[] = [
    {
      name: "Ring for All",
      value: "ring-all",
      tooltip: "All team members will receive the incoming call simultaneously",
      destination_type: "call_center",
    },
    {
      name: "Ring sequentially",
      value: "top-down",
      tooltip: "The call will ring to each team member in the order you've defined",
      destination_type: "call_center",
    },
    {
      name: "Round Robin",
      value: "round-robin",
      tooltip:
        "The call will rotate among team members in a circular fashion, ensuring fair distribution",
      destination_type: "call_center",
    },
    {
      name: "Ring randomly",
      value: "random",
      tooltip: "The call will be randomly assigned to a team member",
      destination_type: "call_center",
    },
    {
      name: "Route to Least Busy Agent",
      value: "agent-with-fewest-calls",
      tooltip:
        "The call will be routed to the team member who has handled the fewest calls that day",
      destination_type: "call_center",
    },
    {
      name: "Route to Idle Agent",
      value: "longest-idle-agent",
      tooltip:
        "The call will be routed to the team member who has been idle the longest for the day",
      destination_type: "call_center",
    },
    {
      name: "Simultaneous ring (Allow calls to ring for everyone)",
      value: "simultaneous",
      tooltip: "The call will ring to every team member in this configuration",

      destination_type: "ring_group",
    },
    {
      name: "Linear ringing (Route calls based on team order)",
      value: "linear",
      tooltip: "The call will ring to each team member in the order you've defined",
      destination_type: "ring_group",
    },
    {
      name: "Route calls based on IVR set up",
      value: "ivr",
      tooltip:
        "This is a clear list of options for the caller to choose from. e.g. 'To talk to sales, press 1'. '1' is the menu option.",
      destination_type: "ivr",
    },
  ];

  const configureCallFlowToEdit = (flow: Configuration) => {
    callConfigStore.currentStep = 5;
    callConfigStore.editFlow = true;

    const getCallDestination = flow.call_destination_application;


    callConfigStore.flowToEdit = flow;
    if (flow.call_destination_application !== "ivr") {
      const getUsers: any =
        getCallDestination === "call_center"
          ? flow?.call_centers[0].call_center_users
          : flow?.ring_groups[0].ring_group_users;
      if (getUsers?.length)
        callConfigStore.teamMembersManage = teamStore.teamMembers
          ?.filter((member: TeamMember) => getUsers?.includes(member.user))
          .sort(
            (a: any, b: any) =>
              getUsers?.indexOf(a.user) - getUsers?.indexOf(b.user)
          ); // get the name list of members in this particular ringroup only and sort according to order
    } else {
      callConfigStore.configureMenuPayload = convertPayloadToEntries(
        flow.ivrs,
        callConfigStore.isAllowedToAccessCallCenter
          ? flow.call_centers
          : flow.ring_groups
      );
      callConfigStore.configuredMenu = [
        ...callConfigStore.configuredMenu,
        callConfigStore.configureMenuPayload,
      ];
    }

    callConfigStore.currentSetBusinessHours = {
      sunday: flow.time_access.sunday,
      monday: flow.time_access.monday,
      tuesday: flow.time_access.tuesday,
      wednesday: flow.time_access.wednesday,
      thursday: flow.time_access.thursday,
      friday: flow.time_access.friday,
      saturday: flow.time_access.saturday,
    };

    callConfigStore.requestPayload = {
      name: flow.name,
      selectedIncomingTemplate: flow.welcome_prompt?.script,
      selectedOutOfOfficeTemplate: flow.out_of_office_prompt?.script,
      call_forwarding_line: flow.call_forwarding_line,
      selectedForward:
        flow.failover_kind === "voicemail" ? "voicemail" : "callForwarding",
    };

    const getStrategy =
      getCallDestination === "call_center"
        ? flow?.call_centers[0]?.strategy
        : flow?.ring_groups[0]?.ring_strategy;

    callConfigStore.callOptionType =
      getCallDestination === "ivr"
        ? getCallOptionByValue("ivr")
        : getCallOptionByValue(getStrategy!);

    callConfigStore.getCurrentWelcomeAudio = flow.welcome_prompt;
    callConfigStore.getCurrentOutOfOfficeAudio = flow.out_of_office_prompt;
  }

  const getCallOptionByValue = (value: string): callOptionType => {
    return callOptionTypes.find(option => option.value === value)!;
  };

  const timezone = ref("Africa/Lagos");
  const titleValidation = ref(false);


  const getKind = (kind: string, teamMemberCount: number) => {
    if (kind === "team member") {
      if (teamMemberCount > 1) {
        return callConfigStore.isAllowedToAccessCallCenter ? "call_center" : "ring_group";
      }
      return "dial";
    }

    if (kind === "sub-menu option" || kind === "repeat") {
      return "submenu";
    }

    if (kind === "terminate") {
      return "bye";
    }

    return kind;
  };
  const getOptionParam = (kind: string, option: any, data: any) => {
    // for dial the param is the agent id
    // for ring_group its teamId
    // repeat is same as its menu id
    // while submenu's param is the id of the submenu entry
    return kind === "dial"
      ? (option.teamMembers.map((member: TeamMember) => member.user)[0]).toString()
      : option.teamId
        ? option.teamId
        : option.subMenuId
          ? option.subMenuId
          : option.kind === "repeat" ? data.id : null;
    // Create an entry for the option
  }

  const convertToEntries = (data: any) => {
    const entries: Entries[] = [];
    const ringGroups: RingGroup[] = [];
    const menus: MenusGreeting[] = [];

    const processMenu = (menuData: any, parentId: string | null = null) => {

      menus.push({
        id: menuData.id,
        prompt_url: menuData.greetingsObject?.recording_url,
        is_primary: parentId ? false : true,
        name: menuData.title,
        uuid: menuData.greetingsObject.uuid
      });

      menuData?.options?.forEach((option: any) => {
        const teamMemberCount = option.teamMembers.length;
        const kind = getKind(option.kind, teamMemberCount);

        let ringGroup = null;
        if (teamMemberCount > 1) {
          if (callConfigStore.isAllowedToAccessCallCenter) {
            ringGroup = {
              id: option.teamId,
              strategy: "ring-all",
              call_center_users: option.teamMembers.map((member: TeamMember) => member.user),
            };
          } else
            ringGroup = {
              id: option.teamId,
              ring_strategy: "simultaneous",
              ring_group_users: option.teamMembers.map((member: TeamMember) => member.user),
            };
        }

        const param = getOptionParam(kind, option, menuData);

        const entry = {
          id: uuidv4(),
          digit: option.key,
          parent_menu_id: menuData.id,
          kind,
          label: option.label,
          param,
        };

        entries.push(entry);
        if (ringGroup) ringGroups.push(ringGroup);
      });

      // Recursively process submenus

      menuData?.submenus?.forEach((submenu: any) => {
        processMenu(submenu, menuData.id);
      });
    };

    // Process the main menu and its nested structure
    processMenu(data);

    return callConfigStore.isAllowedToAccessCallCenter
      ? {
        entries,
        call_centers: ringGroups,
        menus: menus.sort((a, b) => Number(b.is_primary) - Number(a.is_primary)),
      }
      : {
        entries,
        ring_groups: ringGroups,
        menus: menus.sort((a, b) => Number(b.is_primary) - Number(a.is_primary)),
      };

  };



  const convertFromEntries = (data: any) => {

    const submenus = data.menus.filter((menu: any) => !menu.is_primary);

    const originalData: any = {
      title: data.menus[0]?.name || "",
      options: [],
      primary: data.menus[0]?.is_primary || false,
      submenus: [],
      id: data.menus[0]?.id || "",
      script: "",
      greetingsObject: {
        script: data.menus[0]?.name || "",
        id: null,
        business: null,
        type: "WELCOME_PROMPT",
        uuid: null,
        recording_url: data.menus[0]?.prompt_url || "",
        greeting_name: data.menus[0]?.name || "",
        voice_accent: "",
        user: null,
      },
    };
    data?.menus?.forEach((menu: any) => {

      if (menu.is_primary) {
        originalData.id = menu.id;
        originalData.title = menu.name;
        originalData.primary = true;
        originalData.greetingsObject = {
          recording_url: menu.prompt_url,
          type: "WELCOME_PROMPT",
          uuid: menu.uuid
        };
      } else {
        originalData.submenus.push({
          id: menu.id,
          title: menu.name,
          primary: false,
          greetingsObject: {
            recording_url: menu.prompt_url,
            type: "WELCOME_PROMPT",
            uuid: menu.uuid
          },
          options: [],
          isEdit: true,
        });
      }
    });

    // Map through each entry in entries
    data?.entries?.forEach((entry: Entries) => {
      // Check if entry is a main option or a submenu
      // Find associated ring group (if any)
      let ringGroup = data.ring_groups?.find((rg: RingGroup) => rg.id === entry.param);

      const option: OptionSection = {
        label: entry.label,
        key: entry.digit,
        kind: entry.kind === "ring_group" || entry.kind === "call_center" || entry.kind === "dial" ? "team member" : entry.kind === "submenu" && ((entry.param === entry.parent_menu_id)) ? 'repeat' : entry.kind,
        teamMembers: [],
        teamId: entry.kind === 'ring_group' || entry.kind === 'call_center' ? entry.param : null,
        subMenuId: entry.kind === "submenu" && ((entry.param !== entry.parent_menu_id)) ? entry.param : null, // so a submenu that references itself doesnt need a subMenuId
        parent: entry.parent_menu_id
      };

      // Populate team members if it's a ring group
      if (ringGroup) {
        option.teamMembers = callConfigStore.isAllowedToAccessCallCenter ? teamStore.teamMembers?.filter((member: TeamMember) => ringGroup.ring_group_users.includes(member.user)).map((member: any) => ({
          ...member,
          selected: false,
        })) : teamStore.teamMembers?.filter((member: TeamMember) => ringGroup.call_center_users.includes(member.user)).map((member: any) => ({
          ...member,
          selected: false,
        })) // convert id to team member object
      }
      if (entry.kind === "dial") {
        option.teamMembers = teamStore.teamMembers?.filter((member: any) => member.user?.toString() === entry.param?.toString()).map((member: any) => ({
          ...member,
          selected: false,
        })) // convert id to team member object
      }

      if (entry.parent_menu_id === originalData.id) {
        originalData.options.push(option);
      } else {
        const submenu = submenus.find((menu: any) => menu.id === entry.parent_menu_id);
        if (submenu) {
          const submenuOption: OptionSection = {
            label: entry.label,
            key: entry.digit,
            kind: entry.kind === "ring_group" || entry.kind === "dial" ? "team member" : entry.kind === "submenu" && ((entry.param === entry.parent_menu_id)) ? 'repeat' : entry.kind,
            teamMembers: [],
            teamId: entry.kind === 'ring_group' ? entry.param : null,
            subMenuId: entry.kind === "submenu" && ((entry.param !== entry.parent_menu_id)) ? entry.param : null,
            parent: entry.parent_menu_id
          };
          const existingSubmenu = originalData.submenus.find((sm: any) => sm.id === submenu.id);
          if (existingSubmenu) {
            existingSubmenu.options.push(submenuOption);
          } else {
            originalData.submenus.push({
              id: submenu.id,
              title: submenu.name,
              primary: false,
              options: [submenuOption],
              greetingsObject: { recording_url: submenu.prompt_url, uuid: submenu.uuid },
            });
          }

          if (ringGroup) {
            submenuOption.teamMembers = teamStore.teamMembers?.filter((member: any) => ringGroup.ring_group_users.includes(member.user)).map((member: any) => ({
              ...member,
              selected: false,
            })) // convert id to team member object
          }
          if (entry.kind === "dial") {
            submenuOption.teamMembers = teamStore.teamMembers?.filter((member: any) => member.user?.toString() === entry?.param?.toString()).map((member: any) => ({
              ...member,
              selected: false,
            })) // convert id to team member object
          }
        }
      }

    });

    return originalData;
  }
  const convertPayloadToEntries = (data: MenuEntryPayload[], ringGroup?: RingGroup[]): ConfigureMenuPayload => {
    const payload: any = {
      entries: data.flatMap((item: any) => item.entries.map((entry: any) => ({
        ...entry,
        param: String(entry.param) // Convert `param` to string here
      }))
      ),
      menus: data.map(({ entries, ...menu }) => menu),

    }
    if (!callConfigStore.isAllowedToAccessCallCenter) {
      payload.ring_groups = ringGroup || []
    } else {
      payload.call_centers = ringGroup || []
    }

    return payload
  }
  const hasDuplicateParentAndKey = (array: OptionSection[]) => {

    const seen = new Set();

    for (const item of array) {
      const identifier = `${item.parent}-${item.key}`;

      if (seen.has(identifier)) {
        return true; // Duplicate found
      }

      seen.add(identifier);
    }

    return false; // No duplicates
  }
  const showNotification = (text: ResponseError | string, type: string) => {
    if (typeof text === "string") {
      notify({ text, type });
    } else {
      notify({ text: text.message, type });
    }
  };
  function findOptionBySubMenuId(obj: any, targetId: any): any {
    // Check if the current object's id matches
    if (obj.id === targetId) {
      return obj;
    }

    // Check if there's a submenus array to search
    if (obj.submenus && obj.submenus.length > 0) {
      // Recursively search each item in submenus
      for (const sub of obj?.submenus) {
        const result = findOptionBySubMenuId(sub, targetId);
        if (result) {
          return result; // Return as soon as a match is found
        }
      }
    }

    return null; // Return null if no match is found in this branch

  }

  const reconstructNestedData = (data: any) => {

    const { entries, ring_groups, call_centers, menus } = data;

    // Step 1: Map ring_groups by id for easy access
    const ringGroupMap = callConfigStore.isAllowedToAccessCallCenter ? Object.fromEntries(
      call_centers.map((group: any) => [group.id, group])
    ) : Object.fromEntries(
      ring_groups.map((group: any) => [group.id, group])
    );

    // Step 2: Map menus by id to easily find submenus and the main menu
    const menuMap = Object.fromEntries(
      menus?.map((menu: any) => [menu.id, { ...menu, options: [], submenus: [] }])
    );

    // Step 3: Process entries to populate options and submenus
    entries?.forEach((entry: any) => {
      const menu = menuMap[entry?.parent_menu_id];
      if (!menu) return;

      // Determine if the entry is a submenu or an option
      if (entry.kind === 'submenu') {
        const submenu = menuMap[entry.param];
        if (submenu) {
          menu.submenus.push(submenu);
        }
      }
      const teamMemberIdList = ringGroupMap[entry.param]?.ring_group_users || [];
      const option = {
        key: entry.digit,
        kind: entry.kind,
        label: entry.label,
        teamId: !entry.kind.includes('sub') ? entry.param : null,
        subMenuId: entry.kind.includes('sub') ? entry.param : null,
        teamMembers: teamStore.teamMembers
          ?.filter((member: TeamMember) => teamMemberIdList?.includes(member.user))
          .map((member: any) => ({ ...member })),
      };
      menu.options.push(option);
    });

    // Step 4: Find the primary menu and return as the reconstructed data
    const primaryMenu = Object.values(menuMap).find((menu: any) => menu.is_primary);

    // Format the reconstructed menu to include options and submenus correctly
    const formatMenu = (menu: any, visited = new Set()) => {
      // Prevent infinite recursion by checking if we've already visited this menu
      if (visited.has(menu.id)) {
        return null;
      }
      visited.add(menu.id);

      return {
        id: menu.id,
        prompt_url: menu.prompt_url,
        is_primary: menu.is_primary,
        name: menu.name,
        uuid: menu.uuid,
        options: menu.options.map((option: any) => ({
          key: option.key,
          kind: option.kind,
          label: option.label,
          teamId: option.teamId,
          subMenuId: option.subMenuId,
          teamMembers: option.teamMembers,
        })),
        submenus: menu.submenus
          .map((submenu: any) => formatMenu(submenu, visited))
          .filter(Boolean), // Filter out any null values (cyclic references)
      };
    };

    return primaryMenu ? formatMenu(primaryMenu) : null;
  };

  const getOptionsWithOnlySubMenu = (data: any) => {
    return data.filter((option: any) => option.kind.includes('sub'))
  }

  const completeSetup = async () => {

    const hasEntries =
      callConfigStore.configureMenuPayload &&
      Object.keys(callConfigStore.configureMenuPayload)?.length > 0 &&
      Object.keys(callConfigStore.configureMenuPayload?.entries || {})
        .length > 0;

    if (callConfigStore.requestPayload.name.trim().length < 1) {
      showNotification("Please Enter Incoming Call Flow Title", "error");
      titleValidation.value = true;
      return;
    }

    if (
      callConfigStore.requestPayload.selectedForward === "callForwarding" &&
      callConfigStore.requestPayload.call_forwarding_line!.startsWith(
        "+234"
      ) &&
      !isNigerianNumber(callConfigStore.requestPayload.call_forwarding_line!)
    ) {
      showNotification("Enter a valid Call Forwarding line", "error");
      return;
    }


    try {
      callConfigStore.isPublishingAudio = true;
      const destination_id = uuidv4();

      if (callConfigStore.callDestinationType || hasEntries) {
        const payloadRequest: any = {
          call_destination_application: callConfigStore.callDestinationType,
          ...callConfigStore.currentSetBusinessHours,
          call_forwarding_line:
            callConfigStore.requestPayload.selectedForward ===
              "callForwarding"
              ? validateMobile(
                callConfigStore.requestPayload.call_forwarding_line!
              )
              : null,
          failover_kind:
            callConfigStore.requestPayload.selectedForward ===
              "callForwarding"
              ? "mobile"
              : "voicemail",
          fallback_kind: callConfigStore.getCurrentOutOfOfficeAudio
            ? "prompt"
            : "voicemail", // if no out_of_office is created then kind here is mobile ,
          id: uuidv4(),
          name: callConfigStore.requestPayload.name,
          out_of_office_prompt_id:
            callConfigStore.getCurrentOutOfOfficeAudio?.id || null, // null if no out of office is selected
          timezone: timezone.value,
          unavailable_prompt_id: null,
          user_id: currentUserId,
          welcome_prompt_id: callConfigStore.getCurrentWelcomeAudio?.id,
          // welcome_prompt: callConfigStore.getCurrentWelcomeAudio?.id || null,
        };
        if (callConfigStore.callDestinationType) {
          if (callConfigStore.callDestinationType === "ring_group") {
            payloadRequest.payload = {
              id: destination_id,
              ring_strategy: callConfigStore.callOptionType?.value,
              ring_group_users: callConfigStore.teamMembersOrder,
            };
          } else if (callConfigStore.callDestinationType === "call_center") {
            payloadRequest.payload = {
              id: destination_id,
              strategy: callConfigStore.callOptionType?.value,
              call_center_users: callConfigStore.teamMembersOrder,
            };
          } else {
            payloadRequest.payload = callConfigStore.configureMenuPayload;
          }
        }

        await createCallConfig(payloadRequest);
        await assignNumbers(
          payloadRequest.id,
          callConfigStore.businessNumbersToAssignFlow
        );
        if (callConfigStore.callDestinationType === "ivr")
          trackAmplitudeEvent(AMPLITUDE_EVENTS.SAVE_IVR);

        // callConfigStore.assignNumberForFirstFlow = true; // check a if a new call config is added

        callConfigStore.isPublishingAudio = false;
        callConfigStore.isPublished = true;
        // callConfigStore.flowToAssignAfterCreate = callConfigStore.requestPayload.id;
        // personalizationStore.isCompletingSetupFlow = true;

        callConfigStore.isCompletingSetupFlow = true;

        if (!personalizeStore.userOnboardingStatus?.has_set_incoming_call_flow) {
          // show feedback modal for first time settings
          userStore.userFeedbackModal = true
          userStore.setFeedBack('callerGreeting')
          if (userStore.currentFeedback) {
            userStore.currentFeedback.data = {
              greeting_id: destination_id
            }
          }
          await updateOnboardingStage({ has_set_incoming_call_flow: true }); //  update call flow status from frontend
          await personalizeStore.fetchOnboardingStatus()
          engageTrack(
            userStore.currentUserBusinessLevel?.mobile,
            "Created_Personalized_Greeting"
          );
        }
        showNotification(`You have successfully created a greeting`, "success")
        if (utilStore.isMobile) {
          await personalizeStore.fetchOnboardingStatus()
          if (personalizeStore.completedTasksCount >= 4) utilStore.showMobileModal = true // show persistent modal to download app if all steps are completed
          else router.push('/managers/initial-user-onboarding') // route to main onboard view to complete steps

        }

      }
    } catch (error: any) {
      callConfigStore.isPublishingAudio = false;
      showNotification(`${error?.message || error}`, "error");
    }
  };

  return {
    convertToEntries,
    convertFromEntries,
    convertPayloadToEntries,
    hasDuplicateParentAndKey,
    showNotification,
    findOptionBySubMenuId,
    reconstructNestedData,
    getOptionsWithOnlySubMenu,
    callOptionTypes,
    getCallOptionByValue,
    titleValidation,
    completeSetup,
    timezone,
    configureCallFlowToEdit
  }
}