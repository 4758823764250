import { BASE_API } from "../constants";
import { getActiveBusinessId } from "..";
import requestClient from "../api/requestClient";

export const fetchCallConfig = async (link?: string, id?: string) => {

    if (link) return await requestClient.get(link);
    else {
        let url = `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/?page_size=20`
        if (id) url += `&id=${id}`
        return await requestClient.get(url);
    }
};

export const editCallConfig = async (payload: any) => {
    return await requestClient.put(
        `${BASE_API}/api/incoming_call_flow/?business_number=${getActiveBusinessId()}/${payload.id
        }/`,
        { json: payload }
    );
};

export const createCallConfig = async (payload: any) => {
    return await requestClient.post(
        `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/`,
        { json: payload }
    );
};
export const deleteCallConfig = async (dial_plan_id: string) => {
    return await requestClient.delete(
        `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/${dial_plan_id}/`
    );
};
export const assignNumbers = async (
    dial_plan_id: string,
    payload: { business_numbers: number[] }
) => {
    try {
        return await requestClient.put(
            `${BASE_API}/api/business/${getActiveBusinessId()}/dial-group/${dial_plan_id}/assign-number`,
            { json: payload }
        );
    } catch (error) {
    }

};
export const generateUploadUrl = async (payload: { media_type: string; file_name: string, blob_type: string }) => {
    return await requestClient.post(
        `${BASE_API}/api/media/${getActiveBusinessId()}/blob-upload/`,
        { json: payload }
    );
}
export const retrievePromptUrl = async (resource_id: string) => {
    return await requestClient.get(
        `${BASE_API}/api/media/${getActiveBusinessId()}/blobs/${resource_id}/`);
}