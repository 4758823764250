<template>
  <v-dialog
    class="centeredDialog"
    v-model="utilsStore.showMobileModal"
    transition="dialog-bottom-transition"
    persistent
    v-if="smAndDown"
  >
    <v-card width="350" rounded="lg" class="text-center py-7 px-6">
      <img :src="mobileImg" alt="" class="mb-3 mx-auto" />
      <!-- When trying to access retricted link -->
      <template
        v-if="
          utilsStore.restrictedLink ||
          (userStore.user_role !== 'owner' && userStore.user_role !== 'manager')
        "
      >
        <h3>Kindly use a desktop</h3>
        <p>
          We are currently in the process of optimizing our platform for mobile
          devices. <br />
          <br />
          For the best experience, we suggest using a desktop or laptop
          computer.
        </p>
        <v-btn
          class="px-3 mt-5 filled-primary-color mx-auto"
          flat
          color="#09ABE5"
          rounded="lg"
          @click="closeNavigation"
          width="176"
        >
          {{ "Close" }}
        </v-btn>
      </template>
      <!-- After completing onboard process -->

      <template v-else>
        <h4>Use a desktop or download our app</h4>
        <p class="mt-3">
          For the best experience, please download our mobile app on Android or
          iOS to continue.Alternatively, you can access this feature from a
          laptop or desktop computer.
        </p>
        <div class="d-flex justify-center ga-3 mt-5">
          <a
            v-for="product in filteredProductList"
            :key="product.name"
            :href="product.link"
            target="blank"
          >
            <img :src="product.img" height="50" />
          </a>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import mobileImg from "../../../public/assets/Images/mobile.svg";
import { computed, onMounted } from "vue";
import { useUsersStore, useUtilsStore } from "../../stores";
import { useDisplay } from "vuetify";
import { productAndServices } from "../../helpers";

const utilsStore = useUtilsStore();
const userStore = useUsersStore();
const { smAndDown } = useDisplay();
const filteredProductList = computed(() => {
  return smAndDown.value
    ? productAndServices.filter((product) => product.name !== "extensions")
    : productAndServices;
});
const detectMobile = () => {
  // Modern way using User-Agent Client Hints API
  if ((navigator as any).userAgentData) {
    (navigator as any).userAgentData
      .getHighEntropyValues(["mobile"])
      .then((userAgent: any) => {
        utilsStore.showMobileModal = userAgent.mobile;
      })
      .catch(() => {
        fallbackDetectMobile();
      });
  } else {
    // Fallback for older browsers
    fallbackDetectMobile();
  }
};

const fallbackDetectMobile = () => {
  const userAgent =
    navigator.userAgent || (navigator as any).vendor || (window as any).opera;
  const isMobileDevice =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  utilsStore.showMobileModal = isMobileDevice;
};

const closeNavigation = () => {
  utilsStore.showMobileModal = !utilsStore.showMobileModal;
  if (utilsStore.restrictedLink) {
    utilsStore.restrictedLink = "";
  }
};

onMounted(() => {
  if (!utilsStore.mobileDetectionDone) {
    detectMobile();
    utilsStore.mobileDetectionDone = true;
  }
});
</script>


<style lang="scss" scoped>
h3 {
  font-size: 20px;
  font-weight: 700;
}

p {
  font-size: 14px;
  font-weight: 400;
  color: #344054;
}

img {
  width: 64px;
}
</style>