import * as Sentry from "@sentry/vue";

const errorToSentrySeverity: Record<string, string> = {
  warn: "warning",
  info: "info",
  error: "error",
  debug: "debug",
};
const exemptErrors: string[] = [
  "Unhandled Promise Rejection: Disconnect aborted.",
  "Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
  "Uncaught Error: Registerer terminated. Unable to register.",
  "Error while Fetching User details Unauthorized access. Please log in again.",
  "You do not have permission to perform this action.",
  "Non-Error promise rejection captured with value: Unauthorized access. Please log in again."
];
const log = (
  type: any,
  obj?: any,
  message?: string
) => {
  // @ts-ignore
  const logFunction = console[type];
  const logArgs = [...(message ? [message] : []), ...(obj ? [obj] : [])];
  logFunction(...logArgs);
  if (type === "error") {
    if (
      (obj?.status === 401 || obj?.status === 403) || // Check for status
      obj?.code === 5999 // Check for network error code
    ) {
      // Skip sending error with these statuses to sentry
      return;
    }


    // Sentry.captureMessage(message ?? JSON.stringify(obj), errorToSentrySeverity[type]);
    if (exemptErrors.includes(message!)) {
      log("warn", obj, message);
      return;
    }

    // @ts-ignore
    Sentry.captureException(obj?.message || obj, errorToSentrySeverity[type]);

  }
};

export default {
  log: (obj?: any, message?: string) => log("log", obj, message),
  info: (obj?: any, message?: string) => log("info", obj, message),
  error: (obj?: any, message?: string) => log("error", obj, message),
  warn: (obj?: any, message?: string) => log("warn", obj, message),
  debug: (obj?: any, message?: string) => log("debug", obj, message),
};


