import { BASE_API } from '../constants';
import ky, { KyInstance } from 'ky';
import { useAuthStore } from '../../stores';


class RequestClient {
    private publicClient: KyInstance;
    private privateClient: KyInstance;
    private defaultRetryConfig = {
        retries: 3,
        initialDelay: 500,
        backoffFactor: 2,
    };
    private activeRequests: Set<AbortController>; // Track active controllers

    constructor() {
        this.publicClient = this.createClient({}, { credentials: 'include' });
        this.privateClient = this.createClient({
            beforeRequest: [this.addAuthorizationHeader.bind(this)],
        });
        this.activeRequests = new Set(); // Initialize the set
    }

    // create a general KyInstance for both public and private client
    private createClient(hooks = {}, extraOptions: Record<string, RequestCredentials | undefined> = { credentials: 'same-origin' }) {
        return ky.create({
            headers: { Accept: 'application/json' },
            retry: { limit: 0 },
            credentials: extraOptions?.credentials || undefined as RequestCredentials | undefined,
            hooks: {
                afterResponse: [this.handleResponse.bind(this)],
                ...hooks,
            },
        });
    }
    // hook for private request
    private async addAuthorizationHeader(request: Request) {
        const authStore = useAuthStore();
        const { token: accessToken } = authStore;
        if (accessToken) {
            const isExpired = this.isTokenExpired(accessToken);

            if (isExpired) {
                try {
                    const response: any = await this.publicClient.post('auth/token/refresh/').json();
                    authStore.token = response.data.token;
                } catch {
                    authStore.logout();
                    return;
                }
            }

            request.headers.set('Authorization', `Bearer ${authStore.token}`);
        }
    }

    private isTokenExpired(token: string): boolean {
        const decodedToken = this.decodeToken(token as string);
        if (decodedToken && decodedToken.exp) {
            const expirationDate = new Date(0);
            expirationDate.setUTCSeconds(decodedToken.exp);
            const now = new Date();
            now.setSeconds(now.getSeconds() + 20);
            return expirationDate.valueOf() < now.valueOf();
        } else {
            return true;
        }
    }

    private decodeToken = (token: string) => {
        try {
            if (token.split(".").length !== 3 || typeof token !== "string") {
                return null;
            }

            const payload = token.split(".")[1];

            const base64 = payload.replace("-", "+").replace("_", "/");
            const decoded = JSON.parse(atob(base64));

            return decoded;
        } catch (error) {
            return null;
        }
    };


    private async handleResponse(_req: Request, _opt: any, response: any) {

        if (!response.ok) {

            try {
                const errorData = await response.json();

                // include the statuscode to the data response to allow access to code in the app
                response.data = { ...errorData, status: response.status };

            } catch {
                response.data = {
                    code: 'UNKNOWN_ERROR',
                    message: 'An error occurred, please retry or reach out to support',
                };
            }
        } else {
            try {
                // Check if the response is a file download and read response as blob
                const contentType = response.headers.get('Content-Type');
                if (contentType && (contentType.includes('text/csv') || contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))) {
                    // If it's a downloaded file, do not attempt to parse it as JSON. would be handled as blob in download request function
                    return response
                }
                //  Parse JSON automatically for all non download responses
                else {
                    const responseData = await response.json();
                    response.data = responseData; // Attach parsed data to the response
                }
            } catch {
                // If the response cannot be parsed, attach raw text or handle as needed
                response.data = {
                    code: 'UNKNOWN_ERROR',
                    message: 'An error occurred, please retry or reach out to support',
                };
            }
        }
        return response
    }

    private isRetryAbleError(error: any): boolean {
        if (!error.response) return true; // Network errors(Failed to fetch)
        // retryAble errors are server errors
        return error.response.status >= 500;
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private async retryWithBackoff(fn: () => Promise<any>, retryConfig = this.defaultRetryConfig) {
        const { retries, initialDelay, backoffFactor } = retryConfig;

        for (let attempt = 0; attempt <= retries; attempt++) {
            try {
                const req = await fn()
                return req;
            } catch (error) {
                // stop retry attempts if it isnt a retryable error or if maximum attempt is reached
                if (attempt === retries || !this.isRetryAbleError(error)) throw error;

                await this.delay(initialDelay * backoffFactor ** attempt);
            }
        }
    }

    private createAbortController() {
        const controller = new AbortController();
        this.activeRequests.add(controller); // Add to active requests
        controller.signal.addEventListener('abort', () => {
            this.activeRequests.delete(controller); // Remove on abort
        });
        return controller;
    }

    async makeRequest(method: string, url: string, options: any = {}) {
        const client = options.level === 'public'
            ? this.publicClient
            : this.privateClient;
        const controller = this.createAbortController();
        options.signal = controller.signal;

        if (options.extraHeaders) {
            options.headers = { ...options.headers, ...options.extraHeaders };
        }

        // Remove Content-Type for FormData to let the browser handle it
        if (options.body instanceof FormData) {
            if (options.headers) {
                delete options.headers['Content-Type'];
            }
        }
        const fullUrl = url.trim().startsWith('http')
            ? url
            : `${BASE_API}/${url}`;
        try {
            return await this.retryWithBackoff(() => client(fullUrl, { ...options, method }));
        } catch (error: any) {
            // catch error from afterResponse hook
            if (error.response) {
                throw error.response.data;
            }

            // handle network errors
            throw {
                code: '5999',
                message: 'A network error occurred. Please check your connection and try again.',
            };
        }
    }
    async downloadFileRequest(url: string, options: any = {}, filename: string) {
        const client = this.privateClient
        const controller = this.createAbortController();
        options.signal = controller.signal;

        if (options.extraHeaders) {
            options.headers = { ...options.headers, ...options.extraHeaders };
        }

        const fullUrl = url.startsWith('http')
            ? url
            : `${BASE_API}/${url}`;
        try {
            const response = await client(fullUrl, { ...options, method: 'GET' });

            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
                // Optionally, parse the filename from the header
                const filenameMatch = contentDisposition.split('filename=')[1];
                if (filenameMatch) {
                    filename = filenameMatch;
                }
            }
            const blob = await response.blob()
            const blobType = options.blobType || 'application/octet-stream';
            const typedBlob = new Blob([blob], { type: blobType });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(typedBlob);

            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error: any) {

            if (error.response) {
                throw error.response.data;
            }

            throw {
                code: '5999',
                message: 'Failed to download the file. Please try again.',
            };
        }
    }

    // Abort all active requests
    abortAllRequests() {
        this.activeRequests.forEach((controller) => controller.abort());
        this.activeRequests.clear(); // Clear all controllers
    }

    abortRequest() {
        this.abortAllRequests(); // Abort all ongoing requests
    }


    get(url: string, options = {}) {
        return this.makeRequest('GET', url, options);
    }

    post(url: string, options = {}) {
        return this.makeRequest('POST', url, options);
    }

    put(url: string, options = {}) {
        return this.makeRequest('PUT', url, options);
    }

    patch(url: string, options = {}) {
        return this.makeRequest('PATCH', url, options);
    }

    delete(url: string, options = {}) {
        return this.makeRequest('DELETE', url, options);
    }
    download(url: string, options = {}, filename: string) {
        return this.downloadFileRequest(url, options, filename);
    }
}

export default new RequestClient();
