import { useUsersStore } from "../stores";



export const uploadFileToAzureBlob = async (azureBlobUrl: string, selectedFile: File, blobType: string, blobSubType?: string) => {
    const { currentUserBusinessLevel } = useUsersStore();

    if (!selectedFile) {
        return;
    }

    const response = await fetch(azureBlobUrl, {
        method: "PUT", // PUT request for uploading
        headers: {
            "Content-Type": selectedFile.type, // MIME type of the file
            "x-ms-blob-type": "BlockBlob", // Required for Azure Blob Storage
            "x-ms-meta-pressone_user_id": currentUserBusinessLevel?.id,
            "x-ms-meta-pressone_blob_type": blobType,
            "x-ms-meta-pressone_blob_subtype": blobSubType ?? ""
        },
        body: selectedFile, // The actual file to upload
    });
    return response

};



