export const allowedRoutesOnMobileScreen = [
    '/managers/call-records',
    '/managers/caller-greeting',
    '/managers/initial-user-onboarding',
    '/managers/engagement',
    '/managers/profile-update',
    '/sign-in',
    '/sign-up',
    '/complete-signup',
]
export const productAndServices = [
    {
        name: "apple",
        platform: "ios",
        img: "/assets/Images/products/app-store.svg",
        agentImg: "/assets/Images/products/app-store-agent.svg",
        link: "https://apps.apple.com/ng/app/pressone-africa-quick-call/id6451378350",
    },
    {
        name: "playstore",
        platform: "android",
        img: "/assets/Images/products/play-store.svg",
        agentImg: "/assets/Images/products/play-store-agent.svg",
        link: "https://play.google.com/store/apps/details?id=co.pressone.dialler",
    },
    {
        name: "extensions",
        platform: "chrome",
        img: "/assets/Images/products/extension-store.svg",
        agentImg: "/assets/Images/products/extension-store-agent.svg",

        link: "https://chromewebstore.google.com/detail/pressone-africa/mideompoonhnnkldgdmdjkcaednlgagm?authuser=1",
    },
];