import { computed, ref } from "vue";
import {
  fetchNumbers,
  getAddonPlans,
  purchaseAddress,
  purchaseAuthInternationalNumber,
  purchaseInternationalNumber,
  purchaseNumberAndAddress,
} from "./queries/add-ons/address-numbers";
import { objectToQueryString, validateMobile } from "./utils";
import { cleanPayload } from "./callQueue";
import { getActiveBusinessId, getPlans } from "./queries";
import { notify } from "@kyvg/vue3-notification";
import {
  useAuthStore,
  useNumberProvisionStore,
  useUtilsStore,
} from "../stores";
import { trackAmplitudeEvent } from "./integrations/analytics/amplitude";
import { AMPLITUDE_EVENTS } from "./integrations/analytics/events";
import { useAddress } from "./address";
import { useNumberAddressAddOnStore } from "../stores/add-ons/number-address.store";
import { storeToRefs } from "pinia";
import countries from "i18n-iso-countries";
// Register the locales you need (e.g., English and French)
import enLocale from "i18n-iso-countries/langs/en.json";
import { submitCustomerDotIODetails } from "./queries/customerengagement";

countries.registerLocale(enLocale);

const pageSize = 100;

const allCountryFlags = ref<any>([]);

export const useNumber = () => {
  const utilsStore = useUtilsStore();
  const numberAddressAddOnStore = useNumberAddressAddOnStore();

  const {
    countryCode,
    country,
    numberRegion,
    selectedPeriod,
    stateCode,
    internationalPlans,
    selectedNumber,
    selectedPlan,
    formDetails,
    allPlans,
    allPlansCountries,
    selectedCountry,
  } = storeToRefs(numberAddressAddOnStore);

  const authStore = useAuthStore();

  const numberProvisionStore = useNumberProvisionStore();

  const { selectedAddress } = useAddress();

  const handleFetchNumbers = async (
    payload: Record<string, string | number | boolean | null> = {}
  ) => {
    let query = {
      country_code: countryCode.value,
      // state: stateCode.value,
      page_size: pageSize,
      ...payload,
    };
    // to remove empty values and keys
    query = cleanPayload(query);

    const queryString = objectToQueryString(query) as string;
    try {
      const response = await fetchNumbers(queryString);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFlags = (plans: any) => {
    let allFlags = [] as any;
    let allCountries = [] as any;
    plans.map((plan: any) => {
      if (plan?.number_region !== "") {
        allCountries = Array.from(
          new Set([...allCountries, plan.number_region])
        );
        const countryName = countries.getName(plan?.number_region, "en");
        allFlags = [
          ...allFlags.filter(
            (country: any) => country.countryCode !== plan?.number_region
          ),
          {
            flag: `fi-${plan?.number_region.toLowerCase()}`,
            country: countryName,
            countryCode: plan?.number_region,
          },
        ];

        // to reposition Nigeria on the list
      }
    });
    const nigeriaIndex = allFlags.findIndex(
      (item: any) => item.countryCode === "NG"
    );
    const nigeria = allFlags.splice(nigeriaIndex, 1)[0]; // Remove and store Nigeria

    // Insert Nigeria at the third position (index 2)
    allFlags.splice(2, 0, nigeria);

    return allFlags;
  };

  const handleFetchPlans = async (
    payload: Record<string, string | number | boolean> = {}
  ) => {
    let query = {
      ...payload,
    };
    query = cleanPayload(query);
    const queryString = objectToQueryString(query!, '&')!;
    try {
      const response = await getPlans(queryString);
      internationalPlans.value = [
        ...response.data.filter(
          (plan: any) => plan.term == selectedPeriod.value
        ),
      ];
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAddonPlans = async (
    payload: Record<string, string | number | boolean> = {}
  ) => {
    try {
      let query = {
        ...payload,
      };
      query = cleanPayload(query);
      const queryString = objectToQueryString(query!)!;
      const response = (await getAddonPlans(queryString)) as any;
      console.log(response);
      internationalPlans.value = response?.data?.data;
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handlePurchase = async () => {
    try {
      let payload = {
        plan_id: selectedPlan.value.id,
        payment_mode: "CARD",
        email: formDetails.value?.email,
        phone_number: validateMobile(
          formDetails?.value?.phoneNumber,
          formDetails.value?.dialCode
        ),
        quantity: 1,
        // if business id exists, user is logged in
        first_name: getActiveBusinessId() ? null : formDetails?.value.firstName,
        last_name: getActiveBusinessId() ? null : formDetails?.value.lastName,
        business: getActiveBusinessId()
          ? null
          : { name: formDetails.value?.businessName, is_go_global: true },
      } as any;

      // remove null values from payload
      payload = cleanPayload(payload);

      // this caters for when a number is selected
      if (selectedNumber.value) {
        payload.numbers = [
          {
            phone_number: selectedNumber.value?.phone_number,
            provider: selectedNumber.value?.provider,
            country: selectedNumber.value?.country_code,
          },
        ];
      }

      // this caters for when an address is selected
      if (selectedAddress.value) {
        utilsStore.isAddressSelected = true;

        // condition varies in case its a combo plan or just an addon purchase
        if (selectedPlan.value?.plan_type == "number_and_addon") {
          payload.addons = [
            {
              plan_id: selectedPlan.value?.addon_id,
              quantity: 1,
              addon: [
                {
                  address_identifier: selectedAddress.value?.address_identifier,
                  provider: selectedAddress.value?.provider,
                },
              ],
            },
          ];
        } else {
          payload.addon = [
            {
              address_identifier: selectedAddress.value?.address_identifier,
              provider: selectedAddress.value?.provider,
            },
          ];
        }
      }

      // save payload in utils store
      let res;
      switch (selectedPlan.value?.plan_type) {
        case "number_and_addon":
          res = await purchaseNumberAndAddress(payload);
          break;
        case "number":
          if (authStore.isAuthenticated) {
            // for new business
            if (utilsStore.newBusinessObject) {
              payload.business = {
                name: utilsStore?.newBusinessObject.name,
                is_go_global: true,
              };
            } else {
              payload.business_id = getActiveBusinessId();
            }

            res = await purchaseAuthInternationalNumber(payload);
          } else {
            payload.business_id = getActiveBusinessId();
            res = await purchaseInternationalNumber(payload);
          }
          break;
        case "addon":
          numberProvisionStore.selectedNumber = [];
          if (authStore.isAuthenticated) {
            payload.business_id = getActiveBusinessId();
          }
          res = await purchaseAddress(payload);
          break;
        default:
          break;
      }

      // to be able to identify if its go global payment on redirect from paystack
      utilsStore.goGlobalPayment = true;

      // utilsStore.goGlobalcountry

      if (payload?.phone_number) {
        authStore.mobile = payload.phone_number;
      }

      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_SETUP_FLOW);

      trackAmplitudeEvent(AMPLITUDE_EVENTS.CLICKED_MAKE_PAYMENT);

      const customerIOPayload = {
        plan_title: selectedPlan.value?.title,
        ...payload,
      };

      await submitCustomerDotIODetails(customerIOPayload);

      window.location.href = res!.data!.temp_authorization_url;
    } catch (error: any) {
      notify({
        text: error.message || error,
        type: "error",
      });
    }
  };

  const isNumberAndAddon = computed(() => {
    return selectedPlan.value?.plan_type == "number_and_addon";
  });

  return {
    countryCode,
    stateCode,
    handleFetchNumbers,
    handleFetchPlans,
    internationalPlans,
    selectedNumber,
    selectedPlan,
    selectedPeriod,
    numberRegion,
    country,
    formDetails,
    handlePurchase,
    isNumberAndAddon,
    allPlans,
    allPlansCountries,
    allCountryFlags,
    getAllFlags,
    selectedCountry,
    handleGetAddonPlans,
  };
};
