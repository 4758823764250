<template>
  <div class="mb-3">
    <div
      class="upload-container"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="(e) => handleDrop(e)"
      v-if="!audioInfo?.name"
    >
      <img
        src="../../../../assets/Images/icons/import-new.svg"
        alt="Upload Image"
      />
      <label for="file-input" class="custom-file-upload pointer mt-3">
        Click to upload an audio file
      </label>
      <small class="mt-2">Supported file: MP3, WAV, AAC. Max size: 2MB</small>
      <input
        id="file-input"
        type="file"
        accept=".mp3,.wav,.aac"
        @change="(e) => handleFileUpload(e)"
      />
    </div>
    <div v-else>
      <v-card
        class="audio-preview mt-3 rounded-md pa-4 d-flex ga-3"
        color="#F9FAFB"
        height="77"
        elevation="0"
      >
        <img src="/assets/Images/icons/mp3.svg" alt="" />
        <div>
          <p class="steps-title">{{ audioInfo?.name }}</p>
          <p class="logo-format">
            {{ audioInfo?.size }}kb •
            {{ audioInfo?.duration }}
          </p>
        </div>
        <v-spacer></v-spacer>

        <v-progress-circular
          v-if="callConfigStore.isUploadingAudioScript"
          indeterminate
          color="primary"
          size="40"
        ></v-progress-circular>
        <v-btn
          icon
          flat
          v-else-if="callConfigStore.isUploadIsError && selectedFile"
          @click="retryUpload(selectedFile)"
          ><i
            class="icon icon-refresh-circle text-primary text-h5 d-flex justify-center"
          ></i
        ></v-btn>
        <v-btn icon flat color="#F9FAFB" v-else @click="$emit('deleteAudio')"
          ><i
            class="icon icon-trash text-secondary text-h5 d-flex justify-center"
          ></i
        ></v-btn>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import {
  formatDurationInTimerFormat,
  generateUploadUrl,
  getGreetingsById,
  ResponseData,
  retrievePromptUrl,
  uploadFileToAzureBlob,
  useCallConfig,
} from "../../../../helpers";
import { useCallConfigStore } from "../../../../stores";

const callConfigStore = useCallConfigStore();

const emit = defineEmits(["saveAudioInfo", "deleteAudio"]);
const props = defineProps([
  "audioInfo",
  "formType",
  "formToUpdate",
  "audio_source",
]);

const isDragging = ref(false);

const allowedTypes = ["audio/mpeg", "audio/wav", "audio/aac"]; // Valid file types
const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

const selectedFile = ref<File | null>(null); // Store the uploaded file

const { showNotification } = useCallConfig();
const getUploadUrlInfo = ref<{
  data: ResponseData | null;
  status: number;
} | null>(null);
const handleFileUploadToPreAssignedUrl = async (
  preAssignedUploadUrl: string,
  file: File
) => {
  if (preAssignedUploadUrl) {
    try {
      if (file) {
        const response = await uploadFileToAzureBlob(
          preAssignedUploadUrl,
          file,
          "voice_script",
          props.audio_source
        );
        if (!response?.ok) {
          callConfigStore.isUploadingAudioScript = false;
          throw "An error occurred please try again";
        }
      }
    } catch (error) {
      callConfigStore.isUploadingAudioScript = false;
      showNotification(`${error}`, "error");
    }
  }
};
const validateAndSetFile = async (file: File) => {
  try {
    callConfigStore.isUploadingAudioScript = true;

    if (!allowedTypes.includes(file.type)) {
      showNotification(
        "Unsupported file type. Please upload an MP3, WAV, or AAC file.",
        "error"
      );
      return false;
    }

    if (file.size > maxFileSize) {
      showNotification(
        "File is too large. Maximum allowed size is 2MB.",
        "error"
      );
      return false;
    }

    selectedFile.value = file;
    // Extract audio metadata
    const fileType = file.type === "audio/mpeg" ? "audio/mp3" : file.type;
    const audio = new Audio(URL.createObjectURL(file));
    audio.onloadedmetadata = () => {
      const audioDetails = {
        name: file.name,
        type: fileType,
        size: (file.size / 1024).toFixed(1).toString(),
        duration: formatDurationInTimerFormat(Math.floor(audio.duration)), // Limit to 2 decimal places
      };
      emit("saveAudioInfo", audioDetails, props.formType, props.formToUpdate);
    };
    getUploadUrlInfo.value = await generateUploadUrl({
      media_type: fileType,
      file_name: file.name,
      blob_type: "voice_script",
    });

    await handleFileUploadToPreAssignedUrl(
      getUploadUrlInfo.value?.data?.upload_url,
      file
    );
    setTimeout(async () => {
      retrieveUploadedAudioInfo(file);
    }, 20000);
  } catch (error) {
    callConfigStore.isUploadingAudioScript = false;
    callConfigStore.isUploadIsError = true;
    showNotification(`Something went wrong please try again`, "error");
  }
};
const retrieveUploadedAudioInfo = async (file: File) => {
  try {
    callConfigStore.isUploadingAudioScript = true;

    const response = await retrievePromptUrl(
      getUploadUrlInfo.value?.data?.resource_id
    );
    console.log(response);
    const greetingObject = {
      ...response.data,
      recording_url: response.data?.url,
    };
    // fetch greetings script back
    if (response?.data?.id) {
      const voiceScriptResponse = await getGreetingsById(
        undefined,
        response.data.id
      );
      switch (props.audio_source) {
        case "WELCOME_PROMPT":
          callConfigStore.getCurrentWelcomeAudio = voiceScriptResponse.data[0];
          break;
        case "OUT_OF_OFFICE_PROMPT":
          callConfigStore.getCurrentOutOfOfficeAudio =
            voiceScriptResponse.data[0];
          break;
      }
    }
    emit("saveAudioInfo", greetingObject, props.formType, props.formToUpdate);

    callConfigStore.isUploadingAudioScript = false;

    showNotification(`File "${file.name}" uploaded successfully!`, "success");
    await callConfigStore.getGreetingsByType(props.audio_source);
    callConfigStore.isRetrievalError = false;
    callConfigStore.isUploadIsError = false;
    callConfigStore.isUploadingAudioScript = false;
  } catch (error) {
    callConfigStore.isUploadingAudioScript = false;
    callConfigStore.isUploadIsError = true;
    callConfigStore.isRetrievalError = true;
    showNotification(
      `An error occurred when trying to retrieve audio. Please try again`,
      "error"
    );
  }
};
// Handle file upload from input
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) validateAndSetFile(file);
};

// Handle drag-over event
const handleDragOver = () => {
  isDragging.value = true;
};

// Handle drag-leave event
const handleDragLeave = () => {
  isDragging.value = false;
};
const handleDrop = (event: DragEvent) => {
  isDragging.value = false;
  const file = event.dataTransfer?.files[0];
  if (file) validateAndSetFile(file);
};
const retryUpload = (file: File) => {
  if (callConfigStore.isRetrievalError) {
    retrieveUploadedAudioInfo(file);
  } else {
    validateAndSetFile(file);
  }
};
</script>
<style scoped>
.upload-container {
  height: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background-color: #f9fafb;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-container span {
  padding-top: 5px;
}

.custom-file-upload {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #1d2739;
}

input[type="file"] {
  display: none;
}
</style>